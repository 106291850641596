import { termSlugToName } from "../data/ar_taxonomies.js";

export function printTaxonomyPill(termSlug: string, taxonomySlug: string) {
  const termName = termSlugToName(taxonomySlug, termSlug);
  const pill = document.createElement('div');
  pill.classList.add('term_bubble');
  pill.dataset['term'] = termName;
  pill.dataset['slug'] = termSlug;
  pill.innerHTML = termName;

  return pill;
}
