import Userdata from "../classes/Userdata.ts";

export const AppDB = "AR_App";
export const DB_VER = 14;
export const appTitle = "Attachment Repair Membership";

/**
  Define the Userdata singleton. We put it on window to make it explicit that it's global. From anywhere in the app we want to be able to read and write to the Userdata object. It contains all the data about the user's activity: meditations they listen to, SPC progress, their profile, etc.

  As it is a singleton instance, it only contains the data, not methods. Methods are on the window.UserClass instead.
*/
window.Userdata = Userdata.getInstance();
window.UserClass = Userdata;

/**
  This is the app viewport. All code happens within this container div.
*/
window.viewPort = document.querySelector("div#app_viewport");

export const domain = {
  protocol: window.location.protocol, // http | https
  hostname: window.location.hostname, // eg. https://attachmentrepair.com
  host: window.location.host, // hostname plus :port
};
// console.log(domain.protocol, domain.hostname, domain.host)

export const restApiHostUrl = import.meta.env.VITE_HOST_URL;
// export const restApiHostUrl = 'https://attachmentrepair.com';

export const arURL = {
  utm_source: "ar_app",
};

export const tgChannels = {
  frontendErrors: "-1002434015927",
  userFeedback: "",
};

export const userDevice = () => {
  const userAgentString = navigator.userAgent;

  if (/(iPad|iPhone)/.test(userAgentString)) {
    return "iOS";
  } else if (/Android/.test(userAgentString)) {
    return "Android";
  } else if (/Macintosh/.test(userAgentString)) {
    if (navigator.maxTouchPoints > 0) {
      // iPad Pro identifies itself as Macintosh but has > 0 maxTouchPoints
      return "iOS"
    } else {
      return "macOS";
    }
  } else if (/Windows/.test(userAgentString)) {
    return "Windows";
  } else if (/Linux/.test(userAgentString)) {
    return "Linux";
  }
};

export function getEnvironment() {
  if (domain.host.includes("attachmentrepair.com")) {
    return "Production";
  } else {
    return "Development";
  }
}

// App functions
export function isTouchDevice() {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

// Init functions
export function touchLogging() {
  if (settings.touchLogging) {
    // Debugging Functions
    window.addEventListener("touchstart", (event) => {
      const touchedElement = event.target;
      console.log("Touched:", touchedElement, event.target);
    });
  }
}

export const appState = {
  chatInit: false,
  journalInit: false,
  meditationLibraryInit: false,
  spcLibraryInit: false,
  loginToken: Boolean(window.Userdata.jwt),
  meditationLibraryState: {
    page: 1,
    isLoading: false,
    totalPages: undefined,
  },
  spcLibraryState: {
    page: 1,
    isLoading: false,
    totalPages: undefined,
  },
};

export const meditationTaxonomies = {
  purpose: {
    enabled: true,
    input: "input",
    order: 1,
    label: "I want to work on...",
    name: "Purpose",
    name_short: "Purpose",
    type: "taxonomy",
  },
  issue_targeted: {
    enabled: true,
    input: "select",
    order: 2,
    label: "In particular...",
    name: "Issue Targeted",
    name_short: "Issue",
    type: "taxonomy",
  },
  techniques: {
    enabled: false,
    input: "select",
    order: 3,
    name: "Method",
    type: "taxonomy",
  },
  schemas: {
    enabled: false,
    input: undefined,
    type: "taxonomy",
  },
  inner_critics: {
    enabled: false,
    input: undefined,
    type: "taxonomy",
  },
  modes: {
    enabled: false,
    input: undefined,
    type: "taxonomy",
  },
  attachment_styles: {
    enabled: false,
    input: undefined,
    type: "taxonomy",
  },
  dmm_types: {
    enabled: false,
    input: undefined,
    type: "taxonomy",
  },
};

export const spcTaxonomies = {
  issue_targeted: {
    enabled: true,
    input: "select",
    order: 1,
    label: "In particular...",
    name: "Issue Targeted",
    name_short: "Issue",
    type: "taxonomy",
  },
};

export const settings = {
  debugBox: true,
  touchLogging: false,
  spc: {
    filters: {
      issue_targeted: {
        enabled: true,
        input: "select",
        order: 1,
        name: "In particular...",
        type: "taxonomy",
      },
    },
  },
  meditation: {
    options: {
      backSeekOptions: [5, 15],
    },
    filters: {
      purpose: {
        enabled: true,
        input: "input[type='radio']",
        order: 1,
        name: "I want to work on...",
        type: "taxonomy",
      },
      issue_targeted: {
        enabled: true,
        input: "select",
        order: 2,
        name: "In particular...",
        type: "taxonomy",
      },
      techniques: {
        enabled: false,
        input: "select",
        order: 3,
        name: "Method",
        type: "taxonomy",
      },
      duration: {
        enabled: true,
        input: "input[type='radio']",
        order: 4,
        name: "Duration",
      },
      keywords: {
        enabled: true,
        input: "input[type='text']",
        order: 3,
        name: "Keywords",
      },
      schemas: {
        enabled: false,
        input: undefined,
        type: "taxonomy",
      },
      inner_critics: {
        enabled: false,
        input: undefined,
        type: "taxonomy",
      },
      modes: {
        enabled: false,
        input: undefined,
        type: "taxonomy",
      },
      attachment_styles: {
        enabled: false,
        input: undefined,
        type: "taxonomy",
      },
      dmm_types: {
        enabled: false,
        input: undefined,
        type: "taxonomy",
      },
      membership_level: {
        enabled: false,
        input: undefined,
      },
    },
  },
};

export const enabledMeditationTaxonomies = Object.entries(meditationTaxonomies)
  .filter(([_, value]) => value.enabled)
  .map(([key]) => key);

export const enabledSPCTaxonomies = Object.entries(spcTaxonomies)
  .filter(([_, value]) => value.enabled)
  .map(([key]) => key);

export const enabledMeditationFilters = Object.entries(
  settings.meditation.filters,
)
  .filter(([_, value]) => value.enabled)
  .map(([key]) => key);

export const enabledSPCFilters = Object.entries(settings.spc.filters)
  .filter(([_, value]) => value.enabled)
  .map(([key]) => key);

// console.info('enabledMeditationFilters', enabledMeditationFilters)
