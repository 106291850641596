import { Meditation, MeditationSummaryData, SourceSpc } from "./Meditation";
import { MembershipLevel } from "../types/membershipLevel";

export type SpcSummaryData = {
  post_type: "spc";
  postId: number;
  title: string;
  summary: string | undefined; // check if these truly are undefined
  images: {
    spc_image_url_thumbnail: string,
    spc_image_url_medium: string,
    spc_image_url_full: string,
  };
  includedModules: Array<IncludedModules>;
  membershipLevel: MembershipLevel;
  taxonomyData: string[];
  canAccess: boolean;
  subtitle: string | undefined;
};

type Stats = {
  favourite: boolean;
  timestamp: string | undefined;
  completed: boolean;
  started: boolean;
  lastPlayed: string | undefined;
};

type IncludedModules = {
  module_title: string,
  description: string,
  files: Array<IncludedModuleFile>
}

type IncludedModuleFile = FileLecture | FileMeditation | FileDocument;

interface FileLecture extends IncludedModuleFileBase {
  acf_fc_layout: "lecture",
  title: string,
  duration: number | null,
  video_url: string | null,
  audio_url: string | null,
  ar_basics: ARBasics,
  format: {
    value: 'video' | 'audio',
    label: 'Video' | 'Audio'
  },
}

interface FileMeditation extends IncludedModuleFileBase {
  acf_fc_layout: "meditation",
  ar_basics: ARBasics,
  meditation_library_all: MeditationSummaryData,
}

interface FileDocument extends IncludedModuleFileBase {
  acf_fc_layout: "document",
  document_title: string,
  format: "PDF" | "Graphic",
}

type IncludedModuleFileBase = {
  acf_fc_layout: string,
}

type ARBasics = {
  value: boolean,
  label: 'True' | 'False'
}

export class SPC {
  /**
    Track all the instances and ensure there is only ever one of each
  */
  public static instances: Map<number, SPC> = new Map();

  /**
    Instead of initialising this Class with `new` it should be initialised with this method. It returns a new instance if one doesn't exist.

    eg. const spc= SPC.getInstance(SpcSummaryData);
  */
  public static getInstance({
    spcSummaryData,
    spc_id,
  }: {
    spcSummaryData?: SpcSummaryData;
    spc_id?: number;
  }): SPC | undefined {
    // Access an existing SPC by the ID
    if (spc_id) {
      if (SPC.instances.has(spc_id)) {
        return SPC.instances.get(spc_id)!;
      }
    }

    // Initialise new SPC or get one by the ID
    if (spcSummaryData) {
      const spcId: number = spcSummaryData.postId;

      if (SPC.instances.has(spcId)) {
        return SPC.instances.get(spcId)!;
      }

      const instance: SPC = new SPC(spcSummaryData);
      SPC.instances.set(Number(spcId), instance);

      return instance;
    }
  }

  public userStats: Stats = {
    favourite: false,
    timestamp: undefined,
    completed: false,
    lastPlayed: undefined,
    started: false,
  };

  public readonly post_type: "spc";
  public readonly postId: number;
  public readonly title: string;
  public readonly summary: string | undefined; // check if these truly are undefined
  public readonly summary_sentence: string | undefined; // check if these truly are undefined
  public readonly images: {
    spc_image_url_thumbnail: string,
    spc_image_url_medium: string,
    spc_image_url_full: string,
  };
  public readonly includedModules: Array<IncludedModules>;
  public readonly membershipLevel: MembershipLevel;
  public readonly taxonomyData: string[];
  public readonly canAccess: boolean;
  public readonly subtitle: string | undefined;
  public completionCount: number;
  public meditationCount: number;

  private constructor(spcSummaryData: SpcSummaryData) {
    this.postId = spcSummaryData.postId;
    this.post_type = spcSummaryData.post_type;
    this.title = spcSummaryData.title;
    this.summary = spcSummaryData.summary;
    this.summary_sentence = spcSummaryData.summary_sentence;
    this.images = spcSummaryData.images;
    this.includedModules = spcSummaryData.includedModules;
    this.membershipLevel = spcSummaryData.membershipLevel;
    this.taxonomyData = spcSummaryData.taxonomyData;
    this.canAccess = spcSummaryData.canAccess;
    this.subtitle = spcSummaryData.subtitle;

    this.meditationCount = this.countIncludedMeditations();
    this.completionCount = this.countCompletedMeditations();

    this.initialiseSelf(spcSummaryData);
    this.updateCompletions();
  }

  private initialiseSelf(spcSummaryData: SpcSummaryData) {
    SPC.instances.set(Number(this.postId), this);

    this.userStats =
      window.Userdata.spcData.usageData.get(this.postId)
        ?.userStats ?? this.userStats;

    if (this.completionCount > 0) {
      this.userStats.started = true;
    }

  }

  toggleFavourite(newValue: boolean) {
    // console.log('spc toggleFavourite initial state', this.userStats.favourite)

    this.userStats.favourite = newValue || !this.userStats.favourite;
    this.saveToUserUsageData();
    this.watchFavourite();
    return this.userStats.favourite;
  }

  watchFavourite() {
    document.dispatchEvent(
      new CustomEvent("spcFavUpdateEvent", {
        bubbles: true,
        composed: true,
      }),
    );

    document
      .querySelectorAll(`div.spc_row[data-post-id='${this.postId}']`)
      .forEach((spcRow) => {
        const spcRowElement = spcRow as HTMLElement;
        if (spcRowElement) {
          // console.log("Update spc row favourite", this.userStats.favourite);

          spcRowElement.dataset.favourite = String(this.userStats.favourite);
        }
      });
  }

  private instanceUpdated() {
    return (this.userStats.timestamp = new Date().toString());
  }

  public updateCompletions(): void {
    this.updateCompletionCounts();
    this.updateCompletionBars();
  }

  public signalFromChildMeditation(): void {
    this.userStats.started = true;
    this.updateCompletions();
    this.saveToUserUsageData();
  }

  public removeStarted(): void {
    this.userStats.started = false;
  }

  public updateCompletionCounts(): void {
    const completionBars = document.querySelectorAll(
      `div.pane_wrap[data-pane-id='${this.postId}'] completion-bar,
      div.spc_row[data-post-id='${this.postId}'] completion-bar
      `,
    ) as NodeListOf<HTMLElement>;

    if (completionBars) {
      completionBars.forEach((completionBarComponent: HTMLElement) => {
        const counter = completionBarComponent.shadowRoot?.querySelector('div.count');
        if (counter instanceof HTMLElement) {
          counter.innerText = `${this.countCompletedMeditations()} / ${this.countIncludedMeditations()}`;
        }
      })
    }
  }

  public updateCompletionBars(): void {

    const completionBarElements = document.querySelectorAll(
      `div.pane_wrap[data-pane-id='${this.postId}'] div.completion_bar_container completion-bar,
      div.spc_row[data-post-id='${this.postId}'] div.completion_bar_container completion-bar
      `,
    ) as NodeListOf<HTMLElement>;

    const completionPercentage = (this.countCompletedMeditations() / this.countIncludedMeditations()) * 100;

    if (completionBarElements) {
      completionBarElements.forEach((bar) => {
        bar.setAttribute('completion-percentage', String(completionPercentage));
      })
    }
  }

  countIncludedMeditations(): number {

    const filesCount = this.includedModules?.reduce(
      (acc, module) => {
        if (module.files) {
          module.files.forEach((file) => {
            if (file.acf_fc_layout === "lecture") {
              acc["lectures"] += 1;
            } else if (file.acf_fc_layout === "meditation") {
              acc["meditations"] += 1;
            }
          });
        }
        return acc;
      },
      { lectures: 0, meditations: 0 },
    );

    return filesCount.meditations;
  }

  countCompletedMeditations(): number {
    let completedCount: number = 0;

    this.includedModules.forEach(module => {
      module.files.forEach(file => {
        if (file['acf_fc_layout'] === 'meditation') {

          const meditationId: number = Number(file['meditation_library_all'].meditationId);
          const meditationInstance = window.Userdata.meditationData.usageData.get(meditationId);

          if (meditationInstance?.userStats.completed) {
            completedCount += 1;
          }

        }
      })
    });

    // console.log(`countCompletedMeditations(), ${completedCount}/${this.countIncludedMeditations()}`);

    return completedCount;













    // const spcPaneId = document
    //   .querySelector("div.pane_wrap.show[data-pane-type='spc']") as HTMLElement;

    // if (spcPaneId) {
    //   console.log('count hi')
    //   const spcId = Number(spcPaneId.dataset.paneId);
    //   console.log("spc meditation-> notifyspc", spcId);
    //   if (!spcId) return 0;


    //   const completedCount = spcPaneId.querySelectorAll('div.meditation_row[data-completed=true]').length;

    //   console.log(`countCompletedMeditations(), ${completedCount}/${this.countIncludedMeditations()}`);
    //   console.log()
    //   return completedCount;
    // } else {
    //   return null;
    // }
  }

  saveToUserUsageData() {

    const { ...flatSPC }: SPC = this;

    this.instanceUpdated();
    window.Userdata.spcData.update(flatSPC);
  }

  updatePlaycount() {
    /**
      To decide if to update the count or not, we check if the meditation has been restarted since the last time this was pressed.
     **/

    this.userStats.lastPlayed = new Date().toString();

    console.log("this.userStats.playcount");

    // this.saveToUserUsageData();

    document.dispatchEvent(
      new CustomEvent("spcUpdatePlaycount", {
        bubbles: true,
        composed: true,
        detail: {},
      }),
    );

    // this.emitChange();
  }


  unusedCountCompletedMeditations(): number {
    /**
      Search all instantiated meditations with this spcId for ones with .completed
      return the count
    */

    /**
      Ideally we could count like this, but we don't yet have it so that every meditation knows its source. So instead we shall just count the completed rows in the DOM, which will be accurate because every meditation knows itself if it has been completed.
    */
    const completedCount = [...Meditation.instances.values()].reduce(
      (acc, meditation): number => {
        if (meditation.sourceSpc?.spc_id === this.postId) {
          if (meditation.userStats.completed) {
            return acc + 1;
          }
        }
        return acc;
      },
      0,
    );
    return completedCount;
  }

}

window.SPC = SPC.instances
