import { loginForm } from "./login_form.js";
import { registerForm } from "./register_form.js";

// Account Form
export const accountForm = document.createElement("div");
accountForm.setAttribute("id", "account_form_tab_selector_container");
accountForm.innerHTML = `
  <div id='account_tab_container'>
    <div class='tab active' id='login_tab'>Login</div>
    <div class='tab' id='register_tab'>Register</div>
  </div>

  <div id='account_form_container'>
  </div>
`;

accountForm
  .querySelector("div#account_form_container")
  .insertAdjacentElement("afterbegin", loginForm);
accountForm
  .querySelector("div#account_form_container")
  .insertAdjacentElement("afterbegin", registerForm);

const loginTab = accountForm.querySelector("div.tab#login_tab");
const registerTab = accountForm.querySelector("div.tab#register_tab");

loginTab.addEventListener("click", () => {
  accountForm.querySelector("div#login_box").classList.add("active");
  accountForm.querySelector("div#register_box").classList.remove("active");
  loginTab.classList.add("active");
  registerTab.classList.remove("active");
});

accountForm
  .querySelector("div.tab#register_tab")
  .addEventListener("click", () => {
    accountForm.querySelector("div#login_box").classList.remove("active");
    accountForm.querySelector("div#register_box").classList.add("active");
    loginTab.classList.remove("active");
    registerTab.classList.add("active");
  });
