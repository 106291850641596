import { icons } from "../ui_general/icons.js";
import { settings } from "../data/ar_globals";

import meditationPlayerStyles from "../../sass/app/meditation/meditation_player_widget.scss?inline";
import tippyCss from "../../sass/app/ui_general/tippy.scss?inline";
import tippyBaseCss from "tippy.js/dist/tippy.css?inline";

import { MeditationTranscript } from "../classes/MeditationTranscript";
import { formatSecondsToHHMM } from "../ui_general/ar_utils.ts";


export class MeditationPlayer extends HTMLElement {
  constructor() {
    super();

    this.originalDocTitle = "";

    this.blockCompletionEvent = false;
    /**
      When we reach the end of the audio we fire a completion event.
      We don't fire it again unless 'lastEndedRepeatBufferMinutes' has passed compared to the lastEndedTime,
      in order to prevent multiple excess firings.
    */
    this.lastEndedTime = undefined;
    this.lastEndedRepeatBufferMinutes = 10;

    /**
      We consider them to have completed the meditation when they get to 10 seconds before the end.
    */
    this.completeXSecondsBeforeEnd = 10;

    this.attachShadow({ mode: "open" });

    this.state = "loading";
    this.stateConfig = {
      pause: {
        icon: icons.play,
        class: null,
        nextState: "play",
        action: () => this.audio?.pause(),
      },
      loading: {
        icon: icons.loading,
        class: "rotate_spin",
        nextState: "play",
        action: () => this.audio?.play(),
      },
      play: {
        icon: icons.pause,
        class: null,
        nextState: "pause",
        action: () => this.audio?.play(),
      },
      cloudError: {
        icon: icons.cloudError,
        class: null,
        nextState: "loading",
        action: () => this.audio?.play(),
      },
    };

    this.elements = {};

    this.html = `
        <div id='meditation_player_widget_wrapper' class='pane'>
          <div id='meditation_player_widget' data-state='loaded'>

           	<div id="unload_player">
              <iconify-icon noobserver icon="${icons.close}"></iconify-icon>
            </div>

            <div class='audio_player_meditation'>

              <div class='title'></div>

              <audio class='meditation' id='meditation' preload='auto' controls-list='nofullscreen nodownload noremoteplayback'></audio>

              <div class='meditation_controls'>

                <div id='time_controls'>
                  <div class='timestamp_bar_wrap'>

                    <div id='constrainDragHandle'>
                      <div id='dragHandleWrap' data-tippy-content=''>
                        <div id='dragHandle'>
                        </div>
                      </div>
                    </div>

                    <div class='timestamp_bar'>
                      <div class='time_progress_bar prog_bar'></div>
                      <div class='buffered_progress_bar prog_bar'></div>
                    </div>

                  </div>

                  <div class='timestamps_wrap'>
                    <div class='time_elapsed'>--:--</div>
                    <div class='time_total'>--:--</div>
                    <div class='time_back'>x</div>
                    <div class='time_forward'>x</div>
                  </div>
                </div>

                <div id="other_control_buttons">

                  <div class='play_button'>
                    <iconify-icon noobserver icon='${icons.loading}' class='loading rotate_spin'></iconify-icon>
                  </div>

                  <div id="back_seek_buttons">
                   	<div id="back_seek_0" class='meditation_option_button' small>
                      <iconify-icon noobserver icon="${icons.arrowLeftSmall}"></iconify-icon>
                      <div class='text_label'>${settings.meditation.options.backSeekOptions[0]}s</div>
                    </div>
                    <div id="back_seek_1" class='meditation_option_button' small>
                      <iconify-icon noobserver icon="${icons.arrowLeftSmall}"></iconify-icon>
                      <div class='text_label'>${settings.meditation.options.backSeekOptions[1]}s</div>
                    </div>
                  </div>

                  <div id="meditation_timestamps" style='display: none'>
                    <div id="meditation_timestamps_button" class='meditation_option_button menu_button'>
                      <iconify-icon noobserver icon="${icons.timestampList}"></iconify-icon>
                      <iconify-icon noobserver icon="${icons.arrowDown2}" class='menu_icon'></iconify-icon>
                      <div class='text_label'>Sections</div>
                    </div>
                  </div>

                  <div id="meditation_options" style='display: none'>
                    <div id="meditation_setting_button" class='meditation_option_button menu_button'>
                      <iconify-icon noobserver icon="${icons.settings}"></iconify-icon>
                      <iconify-icon noobserver icon="${icons.arrowDown2}" class='menu_icon'></iconify-icon>
                    </div>
                  </div>

                  <div class='volume' disabled>
                    <iconify-icon noobserver icon='${icons.volume}'></iconify-icon>
                    <!-- speaker (mute), speaker-1 (semi), speaker-2 (full) -->
                  </div>

                </div>


              </div>

            </div>

            <div class='audio_player_bgmusic'>
              <div class='title'>Ambient Sounds</div>
              <select id="ambient_select">
                <option value="rain">Rain</option>
                <option value="waves">Waves</option>
                <option value="forest">Forest</option>
              </select>

              <audio class='ambient' controls="controls" loop="true" />
            </div>

          </div>
        </div>`;

    this.shadowRoot.innerHTML =
      `<style>${meditationPlayerStyles} ${tippyCss} ${tippyBaseCss}</style>` + this.html;
  }

  initialize(meditation) {
    // console.log('initialize', dataset);
    this.meditation = meditation;
    this.storeLocalElementsReferences();

    // Setup
    this.elements.audio.setAttribute("src", this.meditation.audioUrl);
    this.elements.title.innerText = this.meditation.title;

    this.attachButtonListeners(meditation);
    this.attachStateListeners();

    /*
      We call an initial play() so that on mobile it will begin to buffer the file.
      Pause it instantly so it doesn't actually play the audio.
    */
    this.audio.load();
    this.playButtonState("loading");

    this.setupTimeDragHandle();
  }

  storeLocalElementsReferences() {
    // Store references to frequently used elements
    this.elements = {
      playerWidget: this.shadowRoot.querySelector(
        "#meditation_player_widget",
      ),
      title: this.shadowRoot.querySelector(".title"),
      playButton: this.shadowRoot.querySelector(".play_button"),
      playIcon: this.shadowRoot.querySelector(".play_button iconify-icon"),
      timeElapsed: this.shadowRoot.querySelector(".time_elapsed"),
      timeTotal: this.shadowRoot.querySelector(".time_total"),
      timestampBar: this.shadowRoot.querySelector(".timestamp_bar"),
      timestampBarWrap: this.shadowRoot.querySelector(".timestamp_bar_wrap"),
      timeProgressBar: this.shadowRoot.querySelector(".time_progress_bar"),
      bufferedProgressBar: this.shadowRoot.querySelector(
        ".buffered_progress_bar",
      ),
      volumeControl: this.shadowRoot.querySelector(".volume"),
      ambientSelect: this.shadowRoot.querySelector("#ambient_select"),
      audio: this.shadowRoot.querySelector("audio#meditation"),
      dragHandleWrap: this.shadowRoot.querySelector("div#dragHandleWrap"),
      dragHandleStyle: getComputedStyle(
        this.shadowRoot.querySelector("div#dragHandle"),
      ),
    };
    this.audio = this.shadowRoot.querySelector("audio#meditation");
    this.playerState = {
      currentTimeXPos: 0,
    };
  }

  connectedCallback() {
    // Store title then update it
    this.originalDocTitle = document.title;
    document.title = this.elements.title.innerText;
  }

  disconnectedCallback() {
    document.title = this.originalDocTitle;
  }

  adoptedCallback() { }

  getTimebarTrackWidth() {
    const trackWidth = window
      .getComputedStyle(this.elements.timestampBar)
      .getPropertyValue("width")
      .replace("px", "");

    const handleWidth = window
      .getComputedStyle(this.elements.dragHandleWrap)
      .getPropertyValue("width")
      .replace("px", "");

    return trackWidth - handleWidth / 2;
  }

  attachButtonListeners(meditation) {
    // console.log('attachButtonListeners()');
    this.elements.playButton.addEventListener("click", () => {
      if (this.audio.paused) {
        this.audio.play();
        meditation.updatePlaycount();
      } else if (!this.audio.paused) {
        this.audio.pause();
      }
    });

    // Backseek
    settings.meditation.options.backSeekOptions.forEach((period, index) => {
      const backSeekButton = this.shadowRoot.querySelector(
        `#back_seek_${index}`,
      );
      const backSeekLabel = backSeekButton.querySelector("div.text_label");
      backSeekLabel.innerHTML = `${period}s`;
      backSeekButton.addEventListener("click", () => {
        const currentTime = this.audio.currentTime;
        this.audio.currentTime = currentTime - period;
      });
    });
  }

  attachStateListeners() {
    this.audio.addEventListener("load", (event) => {
      // console.log('load');
    });

    // Play
    this.audio.addEventListener("play", (event) => {
      // console.log('play')
      this.playButtonState("play");
    });

    // Pause
    this.audio.addEventListener("pause", (event) => {
      // console.log('pause');
      this.playButtonState("pause");
    });

    // Audio State Listeners
    this.audio.addEventListener("canplaythrough", () => {
      if (!this.firstLoad) {
        // Total Time
        const totalTime = this.audio.duration;
        const formattedTimeString = formatSecondsToHHMM(totalTime);
        this.elements.timeTotal.textContent = formattedTimeString;

        // Duration
        this.elements.timeElapsed.textContent = "00:00";
        this.audio.pause();
        this.playButtonState("pause");
      }

      this.firstLoad = true;
    });

    // Loading
    this.audio.addEventListener("progress", () => {
      // console.log('progress...');
      if (this.audio.buffered.length > 0) {
        const bufferedEnd = this.audio.buffered.end(
          this.audio.buffered.length - 1,
        );
        const duration = this.audio.duration;
        const bufferedPercent = (bufferedEnd / duration) * 100;
        this.elements.bufferedProgressBar.style.width = `${bufferedPercent}%`;
        // todo: [] update this manually to be full if a meditation is downloaded.
      }
    });

    // Seeking
    this.audio.addEventListener("seeking", () => {
      // console.log('seeking')
      this.playButtonState("loading");
    });

    // Seeked
    this.audio.addEventListener("seeked", () => {
      // console.log('seeked...........')
      this.playButtonState("play");
    });

    // Current Time
    this.audio.addEventListener("timeupdate", () => {
      const dragHandleWidth = String(
        this.elements.dragHandleStyle.width,
      ).replace("px", "");
      const currentTime = this.audio.currentTime;
      const totalTime = this.audio.duration;
      const percentage = (currentTime / totalTime) * 100;
      const percentageInPX = Number(
        (this.getTimebarTrackWidth() / 100) * percentage,
      );
      const formattedTimeString = formatSecondsToHHMM(currentTime);

      // Update progress bar
      this.elements.timeProgressBar.style.width = `${percentage}%`;

      // Update time position widget
      const translateX = Math.max(
        0,
        Math.min(this.getTimebarTrackWidth(), percentageInPX),
      );
      // console.log('percentageInPX', percentageInPX);
      // console.log('translateX', translateX);

      const finalTranslateX = translateX; // - dragHandleWidth / 2;
      this.elements.dragHandleWrap.style.transform = `translateX(${finalTranslateX}px)`;

      // Time elapsed
      this.elements.timeElapsed.textContent = formattedTimeString;

      this.checkIfCompleted(currentTime);
    });

    this.audio.addEventListener("error", () => {
      console.warn(
        "Meditation load failed due to network (function using native JS)",
      );

      this.playButtonState("cloudError");
    });
  }

  /**
    Check if we have reached the end, in order to fire a completion event
    that the Meditation instance can remember.
    */
  checkIfCompleted(time) {
    const currentTime = this.audio.currentTime;
    const endTime = this.audio.duration;

    // console.log(
    //   "checkIfCompleted",
    //   currentTime,
    //   endTime,
    //   this.blockCompletionEvent,
    // );

    // Ensure that 'lastCompleted' exists on the context
    if (!this.lastCompleted) {
      this.lastCompleted = 0;
    }

    const now = Date.now(); // Current time in milliseconds
    const tenMinutes = 10 * 60 * 1000; // 10 minutes in milliseconds

    if (currentTime >= endTime - this.completeXSecondsBeforeEnd) {
      // console.log("complete, block:", this.blockCompletionEvent);

      // Check if 10 minutes have passed since last completion
      if (
        !this.blockCompletionEvent &&
        now - this.lastCompleted >= tenMinutes
      ) {
        this.markAsComplete();
        this.lastCompleted = now; // Update last completed time
      } else {
        setTimeout(() => {
          this.blockCompletionEvent = false;
        }, 600);
      }
    }
  }

  markAsComplete() {
    console.log("markAsComplete");
    this.meditation.toggleCompleted(true);
  }

  handlePlayButton() {
    const nextState = this.stateConfig[this.state].nextState;
    this.stateConfig[nextState];
    this.playButtonState(nextState);
  }

  playButtonState(newState) {
    // console.log('new playButtonState', newState);
    // console.log('next action', this.stateConfig[newState].nextState);
    this.state = newState;
    const { icon, action } = this.stateConfig[newState];

    // Update button icon
    this.elements.playIcon.setAttribute("icon", icon);
    this.elements.playIcon.className = "";
    this.elements.playIcon.classList.add(
      this.state,
      this.stateConfig[this.state].class ?? "undefined",
    );
  }

  spawnTranscriptViewer() {
    const paneHeader = document.querySelector(
      "div.pane_wrap.vertical div.header",
    );
    const paneHeaderHeight = paneHeader.getBoundingClientRect().height;
    const extraSpacing = "40px";
    const heightOffset = `calc(var(--top-gap) + ${extraSpacing} + ${paneHeaderHeight}px)`;
    console.log("hh", heightOffset);

    const transcriptViewer = document.createElement("div");
    transcriptViewer.setAttribute("id", "transcript_viewer");
    transcriptViewer.style.height = heightOffset;
    transcriptViewer.innerHTML = `
        <div id='transcript_contents'>
        </div>
      `;
    this.transcript.htmlSegmentsArray.forEach((segment) => {
      transcriptViewer
        .querySelector("div#transcript_contents")
        .appendChild(segment);
    });

    document
      .querySelector(
        `div.pane_wrap[data-pane-id='${this.meditation.meditationId}']`,
      )
      .appendChild(transcriptViewer);

    return transcriptViewer;
  }

  setupTimeDragHandle() {
    // console.log('setupTimeDragHandle()');
    const dragGhost = document.createElement("div");
    dragGhost.setAttribute("id", "dragGhost");

    // Draggable timebar progress widget for seeking.
    interact(this.elements.dragHandleWrap).draggable({
      listeners: {
        start: (event) => {
          // set initial vars
          this.trackWidth = this.getTimebarTrackWidth();
          this.dragStartX =
            parseFloat(
              event.target.style.transform
                .replace("translateX(", "")
                .replace("px)", ""),
            ) || 0;

          // make ghost handle from where you started
          dragGhost.replaceChildren(
            this.elements.dragHandleWrap.cloneNode(true),
          );
          this.elements.timestampBarWrap.insertAdjacentElement(
            "beforeend",
            dragGhost,
          );

          this.transcript = new MeditationTranscript(
            this.meditation.meditationId,
            this.meditation.transcript,
          );

          if (this.transcript.text) this.spawnTranscriptViewer();
        },
        move: (event) => {
          // if no audio loaded into player, disable
          if (!this.audio) return;

          const seconds = getPercentageInSeconds();

          if (this.currentTimeTippy?.popperInstance) {
            if (!this.audio.paused) this.audio.pause();

            // Update duration text
            const formattedString = formatSecondsToHHMM(seconds);
            this.elements.timeElapsed.innerText = formattedString;

            // Update tippy position during drag
            this.currentTimeTippy.setContent(formattedString);
            this.currentTimeTippy.popperInstance.update();
          }

          // Move the widget
          const dragX = (this.playerState.currentTimeXPos += event.dx);
          const positionX = Math.max(0, Math.min(this.trackWidth, dragX));
          this.playerState.currentTimeXPos = dragX;
          event.target.style.transform = `translateX(${positionX}px)`;

          // Shift TranscriptViewer
          // get current scroll value
          // increment or decrement it by 1px depending on direction
          const transcriptViewer = document.querySelector(
            "div#transcript_contents",
          );
          if (transcriptViewer) {
            // const scrollPos = transcriptViewer.scrollTop;
            // console.log('scpos', scrollPos, event.dx)

            /**

              Log each time a new item is highlighted

              Then log when nothing is being highlighted.
              In that condition, we apply our smooth scroll attempt

            **/

            (function scrollToTranscriptSegment() {
              const segments = document.querySelectorAll(
                "div#transcript_contents div.segment",
              );
              if (!segments) return;

              let highlighted = false;
              let selected;

              segments.forEach((segment, index) => {
                if (!highlighted && segment.dataset.seconds >= seconds) {
                  // Select only first one that is after current seconds
                  highlighted = true;
                  console.log("got");
                  selected = segments[Math.max(index - 1, 0)];

                  const transcriptViewerHeight =
                    transcriptViewer.getBoundingClientRect().height;
                  const segmentHeight =
                    selected.getBoundingClientRect().height;
                  const scrollPosition =
                    selected.offsetTop -
                    transcriptViewerHeight / 2 +
                    segmentHeight / 2;

                  transcriptViewer.scrollTo({ top: scrollPosition });
                } else {
                  // console.log("\n\n Else");

                  // here i can do the smooth scrolling.
                  // distance constrained to the position of the next/previous segment's position.
                  //

                  // start with positon of selected element
                  // we move based on that
                  if (segment.classList.contains("highlight")) {
                    let limit;
                    let offset = segment.offsetTop;
                    let direction;
                    let scrollPosY;

                    if (event.dx >= 0) {
                      // get distance between the two
                      // get distance on the timedrag bar to the next segment...
                      // lerp those somehow
                      // result value is the distance we add to scrollTop
                      //
                      limit = segments[index + 1].offsetTop;
                      direction = 1;
                    } else {
                      limit = segments[index - 1].offsetTop;
                      direction = -1;
                    }

                    // console.log('offset', offset, direction, 'limit', limit)
                    // console.log('sposY', scrollPosY)
                    transcriptViewer.scrollTo({ top: scrollPosY });

                    // get position of next segment...
                  }
                }

                segment.classList.remove("highlight");
              });
              selected?.classList.add("highlight");
            })();
          }
        },
        end: (event) => {
          const percentageInSeconds = getPercentageInSeconds();
          if (percentageInSeconds > 0) {
            // Update audio player to new position
            this.audio.currentTime = percentageInSeconds;
            this.audio.play();
          }
          this.currentTimeTippy.hide(); // is usually automatic, but sometimes this guarantees that it gets unstuck.

          dragGhost.remove();
          document.querySelector("div#transcript_viewer")?.remove();
        },
      },
      startAxis: "xy",
      lockAxis: "x",
      inertia: false,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: "parent",
          endOnly: false,
        }),
      ],
      cursorChecker: () => {
        return this.audio && this.audio.readyState >= 4
          ? "grab"
          : "not-allowed";
      },
    });

    const getPercentageInSeconds = () => {
      const percentage =
        (this.playerState.currentTimeXPos / this.getTimebarTrackWidth()) *
        100;
      const totalSeconds = this.audio.duration;
      const percentageInSeconds = (totalSeconds * percentage) / 100;
      return percentageInSeconds;
    };

    // Tippy on the widget's currentTimeWidget.
    this.currentTimeTippy = tippy(this.elements.dragHandleWrap, {
      theme: "meditation_player",
      duration: [0, 200],
      offset: [0, 15],
      delay: 0,
      interactive: true,
      touch: "hold",
      arrow: tippy.roundArrow,
      trigger: "click", // todo: [] use 'manual' in prod
      onCreate(instance) {
        instance.popper.id = "tippycurrentTimeWidget";
      },
      appendTo: () => document.body,
      placement: "top",
    });

    // Click it for current time
    const timeWidget = this.elements.dragHandleWrap;
    window.timeWidget = timeWidget;

    const tippyTimeStart = () => {
      const currentTime = this.audio.currentTime;
      const formattedTimeString = formatSecondsToHHMM(currentTime);
      this.currentTimeTippy.setContent(formattedTimeString);
      this.currentTimeTippy.show();
    };

    const tippyTimeEnd = () => {
      setTimeout(() => this.currentTimeTippy.hide(), 100);
    };

    timeWidget.addEventListener("touchstart", tippyTimeStart);
    timeWidget.addEventListener("mousedown", tippyTimeStart);
    timeWidget.addEventListener("mouseup", tippyTimeEnd);
    timeWidget.addEventListener("touchend", tippyTimeEnd);
  }
}


customElements.define(
  "meditation-player",
  MeditationPlayer
);
