import "../chat/ar-chat-characters.js";
import "../chat/ar-chatbot.js";
import { icons } from "../ui_general/icons.ts";

export const sectionChat = document.createElement("section");
sectionChat.setAttribute("id", "chatbot");
sectionChat.dataset.sending = "false";
sectionChat.classList.add("membership", "hidden");
sectionChat.innerHTML = `
  <div id="chat-list-container" class='container'>
    <div class="section_header">

      <div class='header_wrap'>
        <iconify-icon noobserver icon="${icons.postType.chat}"></iconify-icon>
        <div class="label">Chats</div>
      </div>

      <div class="chat_count hidden"></div>
      <div class="controls">
        <button id="new-chat" class='normalButton hidden'>
          <iconify-icon noobserver icon='${icons.plus}'></iconify-icon>
        </button>
        <input
          type="text"
          id="chat-search"
          placeholder="Search chats..."
        />
      </div>
    </div>
    <div id="chat-list"></div>
  </div>

  <div class="resizer">
    <svg width="3">
      <rect></rect>
    </svg>
  </div>

  <div id="main-chat-area" class="out"></div>

  <div class="instructions">
    <p>
      Instructions: The more articulate and cooperative you are with the
      bot, the better the results are. Terse or short messages to the bot
      might lead to worse interactions.
    </p>
  </div>

  <div class="custom_pnr">
    <div class="header">Custom Perfect Nurturers</div>
    <p>
      Make your own custom Perfect Nurturers with different personalities
    </p>
    <div class="pnr_button">New Perfect Nurturer +</div>
    <div class="pnr_library">
      <div class="pnr_profile_box">Mary Attachlington</div>
      <div class="pnr_profile_box">Mary Attachlington</div>
      <div class="pnr_profile_box">Mary Attachlington</div>
    </div>
  </div>

  <div class="privacy">
    <p>
      Privacy: all data is sent to OpenAI. According to their usage
      policy, they do not retain data and do not use it for their model
      training. When the membership feature is released, you will have the
      option to save your chats encrypted.
    </p>
  </div>
  `;
