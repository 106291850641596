export const changeLogContent = `
  <h3>Thu  7, November 2024</h3>
  <p>You can now save favourite Courses for quick access</p>

  <h3>Tue 29, October 2024</h3>
  <p>Launch of Private Beta</p>

  <h3>Hello</h3>
  <p>Welcome to the AR Membership beta.</p>
`;
