// Collapse Box
export function collapseBox({
  name,
  id,
  contents,
  label,
  type = "",
  checkable = "",
} = {}) {
  /**
    type: inline|notice|banner
    checkable: ""|false
  **/
  const boxHTML = `
    <div class='collapse-box' type='${type}' checkable='${checkable}' id='${id}-collapse-box'>

      <input class='collapse_toggle' id='${name}-checkbox' type='checkbox'>
      <label class='collapse_toggle' for='${name}-checkbox'>${label}</label>

      <div class='collapse-contents'>
        ${contents}
      </div>

    </div>
  `;

  return boxHTML;
}
