import * as tippy from "../ui_general/tippy.ts";
import { icons } from "../ui_general/icons.ts";
import { appState } from "../data/ar_globals";
import { terms_issue_targeted } from "../taxonomies/issue_targeted";
import { terms_purpose } from "../taxonomies/purpose";
import { terms_techniques } from "../taxonomies/techniques";
import {
  enabledMeditationFilters,
  enabledSPCFilters,
  settings,
} from "../data/ar_globals";
import { uiGetSPCPosts } from "../sections/section_courses.js";
import { uiGetMeditationPosts } from "../meditation/meditationFunctions.js";

export const postType = {
  meditation: {
    uiGet: uiGetMeditationPosts,
    state: appState.meditationLibraryState,
    enabledFilters: enabledMeditationFilters,
  },
  spc: {
    uiGet: uiGetSPCPosts,
    state: appState.spcLibraryState,
    enabledFilters: enabledSPCFilters,
  },
};

export const libraryFilter = document.createElement("div");
libraryFilter.id = "libraryFilter";
libraryFilter.innerHTML = `

  <div class="taxonomy_filters">

    <div class="filter_group" data-taxonomy='keywords'>
    <input type='text' name='keywords' placeholder='Must include these terms...' />
    </div>

    <div class="filter_group" data-taxonomy="duration">
      <div class="filter_name">Duration</div>
        <fieldset id='taxonomy_duration'>
          <label data-taxonomy-duration='any'>
            <input type='radio' name='duration' value='' checked />
            <div class='label'>Any</div>
          </label>
          <label data-taxonomy-duration='10'>
            <input type='radio' name='duration' value='10' />
            <div class='label'>~ 10 mins</div>
          </label>
          <label data-taxonomy-duration='20'>
            <input type='radio' name='duration' value='20' />
            <div class='label'>20 mins</div>
          </label>
          <label data-taxonomy-duration='30'>
            <input type='radio' name='duration' value='30' />
            <div class='label'>+ 30 mins</div>
          </label>
        </fieldset>
      </div>
    </div>

    <div class="filter_container" style="display:none">
      <div class="filter_widget">
        <div class="filter_name">Date Recorded</div>
        <!-- toggle switch before/after -->
        <!-- date input field calendar -->
        <div class="radio_selector">
          <input type="radio" id="before" name="date_selector" value="before">
          <label for="before">Before</label>
          <input type="radio" id="after" name="date_selector" value="after">
          <label for="after">After</label>
        </div>
        <input type="date">
      </div>
    </div>

    <div class="filter_container" style="display:none">
      <div class="filter_header">Online-Event</div>
    </div>

    <div class="filter_container" style="display:none">
      <div class="filter_header">Self-Paced Course</div>
    </div>

    <div class="filter_container" style="display:none">
      <div class="filter_header">Attachment Style</div>
    </div>

    <div class="filter_container" style="display:none">
      <div class="filter_header">Schema</div>
    </div>

    <div class='button_wrap'>
      <button-std size='normal' id='clear_filter' type='clear' icon='iconamoon:unavailable-bold'></button-std>
      <button-std size='normal' id='apply_filter'icon='iconamoon:funnel-fill' >Apply</button-std>
    </div>

  </div>

  `;

export const filterButton = document.createElement("div");
filterButton.classList.add("action_button");
filterButton.setAttribute("id", "library_filter");
filterButton.innerHTML = `
  <div class='status_dot'>o</div>
  <iconify-icon noobserver icon="${icons.search}"></iconify-icon>
  <div class="label">Filter</div>
`;

tippy.initialiseTippyOn({
  element: filterButton,
  theme: "library_filter",
  placement: "bottom",
  content: "", // Start empty
  allowHTML: true,
  offset: [0, 10],
  animation: "shift-away",
  duration: [200, 100],
  onShow: (instance) => {
    // Clear any existing content from the tooltip and append fresh libraryFilter.
    instance.setContent("");
    instance.setContent(libraryFilter);
    filterButton.classList.add("open");
  },
  onHidden: (instance) => {
    // Ensure libraryFilter goes back to libraryMain when the tooltip is hidden.
    const libraryMain = document.querySelector("div#library_main");
    if (!libraryMain.contains(libraryFilter)) {
      libraryMain.append(libraryFilter);
      filterButton.classList.remove("open");
    }
  },
});

// Build out the input forms
// 1. Loop over enabledMeditationFilters
// 2. Check the type, build a form depending on the type
// 3. Append result into libraryFilter.querySelector("div.container)

enabledMeditationFilters.reverse().forEach((taxonomyName) => {
  const formContainer = document.createElement("div");
  formContainer.classList.add("filter_group", "topicselector");

  formContainer.dataset.taxonomy = taxonomyName;
  formContainer.innerHTML = `<div class="filter_name">${settings.meditation.filters[taxonomyName].name}</div>`;

  // Build one or another type of form depending on the taxonomy
  const controlType = settings.meditation.filters[taxonomyName].input;

  if (controlType === "input[type='radio']") {
    buildFilterFormRadio(taxonomyName).forEach((input) => {
      formContainer.appendChild(input);
    });
  } else if (controlType === "select") {
    formContainer.appendChild(buildFilterFormSelect(taxonomyName));
  }

  if (taxonomyName !== "duration") {
    libraryFilter
      .querySelector("div.taxonomy_filters")
      .insertAdjacentElement("afterbegin", formContainer);
  }
});

// Map Library Filter UI Controls to internal state of the libraryFilter object
enabledMeditationFilters.forEach((taxonomyName) => {
  const controlType = settings.meditation.filters[taxonomyName].input;

  const queryString = `div.filter_group[data-taxonomy='${taxonomyName}'] ${controlType}[name='${taxonomyName}']`;
  const filterGroups = libraryFilter.querySelectorAll(queryString);
  // console.log('each', taxonomyName, controlType, filterGroups)

  filterGroups.forEach((filterGroup) => {
    if (controlType === "input[type='text']") {
      filterGroup.addEventListener("input", (event) => {
        libraryFilter[taxonomyName] = event.target.value;
      });
    } else {
      filterGroup.addEventListener("change", (event) => {
        libraryFilter[taxonomyName] = event.target.value;
      });
    }
  });
});

// Functions
export function getLibraryFilterSettings(postSlug = "meditation") {
  const enabledFilters = postType[postSlug].enabledFilters;
  // console.log('getLibraryFilterSettings', enabledFilters);

  const libraryFilterSettings = enabledFilters.reduce((acc, taxonomyName) => {
    acc[taxonomyName] = libraryFilter[taxonomyName];
    return acc;
  }, {});

  return libraryFilterSettings;
}

// Universal update function for status dot
function updateStatusDot() {
  const statusDot = document.querySelector(
    "div#library_filter.action_button div.status_dot",
  );
  const checkFilter = Object.entries(getLibraryFilterSettings()).some(
    ([_, value]) => value,
  );

  statusDot?.classList.toggle("active", checkFilter);
}

function addDefaultTerm(arrayOfTerms) {
  arrayOfTerms.unshift({
    name: "Any",
    slug: "",
    description: "",
    default: true,
  });
  return arrayOfTerms;
}

function buildFilterFormSelect(taxonomyName) {
  // Setup selectWrap
  const selectWrap = document.createElement("div");
  selectWrap.classList.add("select_wrap");
  selectWrap.innerHTML = `
    <select name="${taxonomyName}" id="${taxonomyName}">
    </select>
  `;

  let tempTerms;
  switch (taxonomyName) {
    case "issue_targeted":
      tempTerms = terms_issue_targeted;
      break;
    case "techniques":
      tempTerms = terms_techniques;
      break;
  }

  // Generate tempTerms quickly:
  // `jq '[[[.[] | {name, slug, count, description}]]]' terms.json`

  if (!tempTerms) return selectWrap;

  // Add default "Any" term
  tempTerms = addDefaultTerm(tempTerms);

  // Loop over each item, build out...
  tempTerms.forEach((term) => {
    if (term.count === 0) return;

    // Filter <select> options block
    const termCount = term.name !== "Any" ? ` (${term.count})` : "";
    selectWrap.querySelector("select").innerHTML += `
        <option value="${term.slug}">${term.name}${termCount}</option>
      `;
  });

  return selectWrap;
}

function buildFilterFormRadio(taxonomyName) {
  // call the restAPI here, get list of terms
  let tempTerms = terms_purpose;

  // Generate tempTerms quickly:
  // `jq '[[[.[] | {name, slug, count, description}]]]' purpose.json`

  if (taxonomyName === "purpose") {
    tempTerms = addDefaultTerm(tempTerms);
  }

  // Loop over each item, build out...
  const inputs = [];
  Object.values(tempTerms).forEach((term) => {
    if (term.count === 0) return;

    // Filter input radio block
    const topicDiv = document.createElement("div");

    // todo: [] This should be abstracted better into the function that gives "any".
    let termDefault;
    let termSlug;
    let termValue;
    if (term.name === "Any") {
      termDefault = "checked";
      termSlug = "any";
      termValue = "";
    } else {
      termDefault = "";
      termSlug = term.slug;
      termValue = term.slug;
    }

    topicDiv.classList.add("topic", termSlug);
    topicDiv.innerHTML = `
        <label data-taxonomy-${taxonomyName}="${termValue}">
          <div class="label">${term.name}</div>
          <input type="radio" name="${taxonomyName}" value="${termValue}" ${termDefault}>
        </label>
      `;

    // Description
    if (term.slug !== "") {
      const descriptionButton = document.createElement("div");
      descriptionButton.classList.add("description");
      descriptionButton.innerHTML = "i";
      initialiseTippyOn({
        element: descriptionButton,
        theme: "library_filter_description",
        placement: "top",
        content: term.description,
        allowHTML: true,
        // offset: [0, 10],
      });
      topicDiv.appendChild(descriptionButton);
    }

    // Save it
    inputs.push(topicDiv);
  });

  return inputs;
}

// Apply All
libraryFilter
  .querySelector("button-std#apply_filter")
  .addEventListener(
    "click",
    async function applyRESTFilter({ postSlug = "meditation" }) {
      // close the tippy popup
      filterButton._tippy.hide();

      try {
        // Invoke the network request and wait for it to complete
        await postType[postSlug].uiGet.call(null, {
          page: 1,
          ...getLibraryFilterSettings(postSlug),
        });

        // ButtonArrow.updateAllButtons();
      } catch (error) {
        console.error(`Failed to fetch ${postSlug} posts:`, error);
      } finally {
      }

      // Save state into local storage (only after user confirms with Apply button)
      postType[postSlug].enabledFilters.forEach((taxonomyName) => {
        window.Userdata.setSettings(
          `${postSlug}_libraryFilter_${taxonomyName}`,
          libraryFilter[taxonomyName],
        );
      });
    },
  );

// Clear All
libraryFilter
  .querySelector("button-std#clear_filter")
  .addEventListener("click", () => clearRESTFilter());

function clearRESTFilter(postSlug = "meditation") {
  postType[postSlug].enabledFilters.forEach(
    (taxonomyName) => (libraryFilter[taxonomyName] = ""),
  );

  // Remove CSS Highlight Sheets
  document
    .querySelectorAll(`style[title=${postSlug}LibraryFilterHighlights]`)
    .forEach((sheet) => sheet.remove());

  // Reset Status dot
  updateStatusDot();
}

function loadRESTFilter() {
  /**
    Load any RESTFilter settings from localStorage
  */
  ["meditation", "spc"].forEach((postSlug) => {
    // Load state out of local storage
    postType[postSlug].enabledFilters.forEach((taxonomyName) => {
      const localStorageValue = window.Userdata.getSettings(
        `${postSlug}_libraryFilter_${taxonomyName}`,
      );

      if (localStorageValue) {
        libraryFilter[taxonomyName] = localStorageValue;
        // [] Apply the CSSStyleSheet function here if applicable
      }
    });
  });
}

document.addEventListener('loginEvent', () => {
  clearRESTFilter();
  loadRESTFilter();
});
document.addEventListener('logoutEvent', () => clearRESTFilter());

// Library Filter Getters/Setters
Object.defineProperty(libraryFilter, "duration", {
  get: function () {
    const durationSelected = this.querySelector(
      "div.filter_group[data-taxonomy=duration] input:checked",
    );
    return durationSelected.value || "";
  },
  set: function (newValue = "") {
    if (!newValue) {
      newValue = `value="${newValue}"`;
    } else {
      newValue = `value='${newValue}'`;
    }

    const input = this.querySelector(
      `div.filter_group[data-taxonomy=duration] input[${newValue}]`,
    );

    if (input) {
      input.checked = true;
    }
    updateStatusDot();
  },
});

/**
  Refactor these get/set functions to operate depending on the input field type (text/select/radio etc) so they're more generalisable. Then make them auto-set themselves up based on whether the field is enabled or not.
*/

Object.defineProperty(libraryFilter, "keywords", {
  get: function () {
    const textSelected = this.querySelector(
      "div.filter_group[data-taxonomy=keywords] input",
    );
    return textSelected.value || "";
  },
  set: function (newValue = "") {
    if (!newValue) {
      newValue = `value="${newValue}"`;
    } else {
      newValue = `value='${newValue}'`;
    }

    const input = this.querySelector(
      `div.filter_group[data-taxonomy=keywords] input[${newValue}]`,
    );

    if (input) {
      input.checked = true;
    }
    updateStatusDot();
  },
});

Object.defineProperty(libraryFilter, "purpose", {
  get: function () {
    const purposeSelected = this.querySelector(
      "div.filter_group[data-taxonomy=purpose] input:checked",
    );
    return purposeSelected.value || "";
  },
  set: function (newValue) {
    if (!newValue) {
      newValue = `value="${newValue}"`;
    } else {
      newValue = `value=${newValue}`;
    }

    const input = this.querySelector(
      `div.filter_group[data-taxonomy=purpose] input[${newValue}]`,
    );

    if (input) {
      input.checked = true;
    }
    updateStatusDot();
  },
});

Object.defineProperty(libraryFilter, "techniques", {
  get: function () {
    const techniquesSelect = this.querySelector(
      "div.filter_group[data-taxonomy=techniques] select",
    );
    const techniquesSelected =
      techniquesSelect.options[techniquesSelect.selectedIndex];
    const techniquesValue =
      techniquesSelected.value !== "any" ? techniquesSelected.value : "";
    return techniquesSelected ? techniquesValue : "";
  },
  set: function (newValue) {
    const input = this.querySelector(
      `div.filter_group[data-taxonomy=techniques] select`,
    );
    if (input) {
      input.value = newValue;
    }
    updateStatusDot();
  },
});

Object.defineProperty(libraryFilter, "issue_targeted", {
  get: function () {
    const issueSelect = this.querySelector(
      "div.filter_group[data-taxonomy=issue_targeted] select",
    );
    const issueSelected =
      issueSelect.options[issueSelect.selectedIndex] ?? issueSelect.options[0];

    if (issueSelected) {
      const issueValue =
        issueSelected?.value !== "any" ? issueSelected.value : "";
      return issueSelected ? issueValue : "";
    } else {
      return "";
    }
  },
  set: function (newValue) {
    const input = this.querySelector(
      `div.filter_group[data-taxonomy=issue_targeted] select`,
    );
    if (input) {
      input.value = newValue;
    }
    updateStatusDot();
  },
});

// Initialise
document.addEventListener("DOMContentLoaded", () => {

});
