import { authHeader } from "./restapi_login_user.js";
import { apiURL } from "./restapi_requests";

export async function updateUserData(dataToSend) {
  const jsonData = JSON.stringify(dataToSend);
  console.log("dataToSend", dataToSend);
  // console.log("jsonData", jsonData);

  const url = apiURL("ar", "users/update");
  console.log("url:", url);

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
      body: jsonData,
      keepalive: true,
    });

    if (!response.ok) {
      // Check if the response has a status code outside 200-299
      console.log('!response', response)
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    console.log("updateUserData():", result);
    return result;
  } catch (error) {
    console.error("Error in updateUserData:", error.message);
    // You can return a more user-friendly message or an object containing error details
    return { success: false, message: error.message };
  }
}

/**

  keyNames is an array of string keynames corresponding to the ACF fields
  that store each user's data.

**/
export async function getUserData(keyArray) {
  const parameters = Object.fromEntries(keyArray.map((key) => [key, true]));

  const response = await fetch(apiURL("ar", "users/get"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
    body: JSON.stringify(parameters),
  });

  const result = await response.json();
  console.log("getUserData() Parameters:", parameters);
  console.log("getUserData()", result);

  return result;
}
