import { sectionStart } from "../sections/section_start";

if ("serviceWorker" in navigator) {
  const serviceWorkerSupported = "✅ Service Worker is supported";
  console.log(serviceWorkerSupported);
  sectionStart.querySelector("div#service_worker_status").innerText =
    serviceWorkerSupported;
} else {
  const serviceWorkerNotSupported = "❌ Service Worker not supported";
  console.log(serviceWorkerNotSupported);
  sectionStart.querySelector("div#service_worker_status").innerText =
    serviceWorkerNotSupported;
}
