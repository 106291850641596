import { slidePane } from "../ui_components/slidePane.js";
import { printUpgradeButton } from "../ui_components/upgrade_button.js";
import { printTaxonomyPillsContainer } from "../ui_components/taxonomyPill";
import { icons } from "../ui_general/icons.ts";
import "../meditation/meditationPlayer.ts";
import { createMeditationPaneContents } from "./meditationPane.js";
import { createMenuWidget } from "../ui_components/menu_widget.js";
import { membershipPill } from "../ui_components/membership_pill.js";

import { Meditation, MeditationSummaryData } from "../classes/Meditation";

/**
  Card that displays a Meditation file in a list
*/

export type RowParams = {
  meditation: Meditation;
  showMenu?: boolean;
  page?: "library" | "spc" | "journal";
  showTaxonomies?: boolean;
  size?: "normal" | "mini";
};

export class MeditationRow {
  id: number;
  model: Meditation;
  DOMinstance: HTMLElement;

  constructor(rowParams: RowParams, meditation: Meditation) {
    this.id = meditation.meditationId;
    this.model = meditation;
    this.params = [rowParams, meditation];
  }

  render() {
    // this.meditationSummaryData = params.meditation;
    // this.targetName = params.page;
    // this.showTaxonomies = params.showTaxonomies;
    // this.showMenu = params.showMenu;
    // this.size = params.size;

    /**
      here we need to go over each instance of this view and update its innerHTML.
      */

    console.log("render!");
    this.DOMinstance = "lol";
  }

  view(rowParams: RowParams, meditationSummaryData: MeditationSummaryData) {
    const row = this.createBlankRow(rowParams.size);

    setDatasetProperties(row, this.model);
    attachMeditationRowEventListeners({ row: row, meditation: this.model });

    if (rowParams.showMenu) {
      row
        .querySelector("div.controls")
        .append(createMenuWidget("meditation", this.model, row, "vertical"));
    }
    this.DOMinstance = row;
    return row;
  }

  createBlankRow(size = "normal") {
    const blankRow = document.createElement("div");
    blankRow.className = `meditation_row ${size}`;

    const dateRecorded = dateformat(this.model.dateRecorded, "mid");

    blankRow.innerHTML = `
      <div class='meditation_row_contents'>
       	<div class='meditation_data'>

          <div class='audioTrigger play'>
            <iconify-icon noobserver icon='${icons.lotusBold}'></iconify-icon>
          </div>

          <div class='audioTrigger pause' style='display: none'>
            <iconify-icon noobserver icon='${icons.pause}'></iconify-icon>
          </div>

          <div class='title'>${this.model.title}</div>

          <div class='side_data'>
            <div class='duration'>${this.model.duration} mins</div>

            <div class='controls'>
              <div class='source_spc pill' data-tippy-content='it works' style='display: none'>SPC</div>
            </div>

          </div>
        </div>

        <div class='extra_data'>
        </div>
      </div>

      <div class='row_secondary_data'>
        ${membershipPill(this.model.membershipLevel).outerHTML}
        <div class='star_rating'></div>
        <div class='up_down_vote_widget'></div>
        <div class='date_recorded'>${dateRecorded}</div>
      </div>
      `;
    return blankRow;
  }
}

export function createMeditationRow() {
  // const meditation = Meditation.getInstance(meditationSummaryData);
  // meditation.controller.addView(`bob ${meditation.meditationId}`);
  // console.log("createMeditationRow", meditation);

  const row = createBlankRow(meditation, size);

  (function dataset() {
    setDatasetProperties(row, meditation);
  })();

  if (row.dataset.canAccess === "true") {
    attachMeditationRowEventListeners({ row, meditation });

    if (showMenu) {
      row
        .querySelector("div.controls")
        .append(createMenuWidget("meditation", meditation, row, "vertical"));
    }
  } else {
    row
      .querySelector("div.meditation_row_contents")
      .appendChild(printUpgradeButton(meditation));
  }

  if (size === "mini") {
    return row;
  }

  if (showTaxonomies) {
    const taxonomyPills = printTaxonomyPillsContainer({ postInstance });
    if (taxonomyPills) {
      row.querySelector("div.extra_data").appendChild(taxonomyPills);
    }

    const spcPill = printSPCPill(row);
    if (spcPill) {
      row.querySelector("div.extra_data").appendChild(spcPill);
    }
  }

  // Journal
  if (meditation.journalEntries().length) {
    const journalCount = document.createElement("div");
    journalCount.classList.add("journal_count");
    journalCount.innerHTML = `
      <iconify-icon noobserver icon='${icons.postType.journal}' class='journal'></iconify-icon>
      <div class='label'>Journal</div>
      <span class='count'>${meditation.journalEntries().length}</span>
    `;

    row.querySelector("div.side_data").appendChild(journalCount);
  }

  // Transcript
  if (meditation.transcript) {
    const transcript = document.createElement("div");
    transcript.classList.add("transcript");
    transcript.innerHTML = `
      <iconify-icon noobserver icon='${icons.postType.transcript}' class='journal'></iconify-icon>
      <div class='label'>Transcript</div>
    `;

    row.querySelector("div.side_data").appendChild(transcript);
  }

  // if we have both, then set them to be the mini-version of them so they fit
  if (meditation.journalEntries().length && meditation.transcript) {
    const minis = ["div.journal_count", "div.transcript"];
    minis.forEach((mini) => row.querySelector(mini).classList.add("mini"));
  }

  return row;
}

export function printSPCPill(row) {
  if (row.dataset.spc_id !== undefined) {
    const pillsContainer = document.createElement("div");
    pillsContainer.classList.add("pills_container");

    const spcPill = document.createElement("div");
    spcPill.classList.add("spc_pill");
    spcPill.innerHTML = `
      <div class='label'>Source</div>
      <div class='post_type'>Self-Paced Course</div>
      <img class='spc_image' src='${import.meta.env.VITE_HOST_URL}/${row.dataset.spc_image_url_thumbnail}' />
      <div class='spc_title'>${row.dataset.spc_name}</div>
    `;
    pillsContainer.appendChild(spcPill);
    return pillsContainer;

    /*

    - [] enable this later when we can make individual calls to the REST API to get the spcPost to pass in.
    const spcId = row.dataset.postId;

    const spcPost = getSPCList(spcId) ?? restAPIRequest({spcId: spcId});

    spcPill.addEventListener("click", () => {
        linkToSPCPaneBlock(spcPost)
    });

    */
  }
}

export function setDatasetProperties(row, data) {
  delete data.player;
  // delete data.transcript;

  const favourite =
    window.Userdata.meditationData.usageData.get(Number(data.meditationId))
      ?.userStats.favourite ?? false;

  row.dataset.favourite = favourite;

  Object.entries(data).forEach(([key, value]) => {
    // Convert key from kebab-case to camelCase for dataset compatibility
    const sanitizedKey = key.replace(/-([a-zA-Z])/g, (match, group1) =>
      group1.toUpperCase(),
    ); // Uppercase letters following dashes
    if (typeof value === "object" || Array.isArray(value)) {
      // console.log('Objvalue', key, value);
      // If value is an object, recursively set its properties.
      // Create a container for nested data if it doesn't exist.
      // const subElement = row.querySelector(`[data-id="${key}"]`) || row;
      // setDatasetProperties(subElement, value);
    } else if (sanitizedKey !== "transcript") {
      // console.log('stringvalue', key, value.substring(0, 50));
      // Ensure the value is converted to string
      const stringValue = String(value);
      // Assign the value to the dataset
      row.dataset[sanitizedKey] = stringValue;
    }

    // if (typeof value === 'string' && value.includes('[')) {
    //   element.dataset['skip'] = ''
    // }
  });
}

function attachMeditationRowEventListeners({ row, meditation }) {
  const state = {
    title: row.dataset.title,
    audioUrl: row.dataset.audioUrl,
    meditationId: row.dataset.meditationId,
  };
  // Put meditation data such as the src url into a global meditation player object
  // that the web component can access.

  // spawn a pane
  row.addEventListener("click", () => {
    slidePane({
      direction: "vertical",
      element: createMeditationPaneContents({ row, meditation }),
      title: row.dataset.title,
      dataset: row.dataset,
      target: row,
    });
  });
}

window.createMeditationPlayer = function (meditationId, meditationInstance) {
  // console.log('window.createMeditationPlayer()', meditationId)
  const player = document.createElement("meditation-player");
  // const meditationData = window.meditationPlayerList[meditationId];

  player.initialize(meditationInstance);

  return player;
};
