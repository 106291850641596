export function membershipPill(
  membershipLevelObj = {
    value: "tier_public",
    label: "No Account",
  },
) {
  const labelString = membershipLevelObj.label;
  const stars = {
    "Premium Membership": "⭐⭐",
    "Pro Membership": "⭐",
  };
  const starsDiv = document.createElement("div");
  starsDiv.classList.add("stars");
  starsDiv.innerHTML = stars[labelString] ?? "";

  const membershipPill = document.createElement("div");
  membershipPill.classList.add("membership_level_pill");
  membershipPill.dataset.membershipLevel = membershipLevelObj.value;
  membershipPill.appendChild(starsDiv);
  membershipPill.innerHTML += `${membershipLevelObj.label}`;

  return membershipPill;
}
