import { createMeditationRow } from "../meditation/meditation_row";
import { icons } from "../ui_general/icons.ts";
import { createMenuWidget } from "../ui_components/menu_widget";
import { JournalEntry } from "../classes/JournalEntry";

export function journalEntryComponentDiv(
  journalEntryObj,
  linkToMeditation = false,
) {
  const date = dateformat(journalEntryObj.date, "fullDate");
  const journalEntryDiv = document.createElement("div");
  const journalText = journalEntryObj.text.replaceAll("\n", "<br/>");

  journalEntryDiv.classList.add("journal_entry");
  journalEntryDiv.dataset.uuid = journalEntryObj.uuid;
  journalEntryDiv.dataset.meditationId = journalEntryObj.meditationId;

  journalEntryDiv.innerHTML = `
    <div class='journal_contents'>

      <div class='journal_date'>
        <iconify-icon noobserver icon='${icons.postType.journal}'></iconify-icon>
        <div class='date'>${date}</div>
      </div>

      <div class='journal_text'>${journalText}</div>
    </div>
  `;

  if (linkToMeditation) {
    journalEntryDiv.innerHTML += `<div class='from'>From</div>`;
    journalEntryDiv.appendChild(
      createMeditationRow({
        meditationSummaryData: journalEntryObj.meditationData,
        showTaxonomies: false,
        size: "mini",
      }),
    );
  }

  // Menu button
  const deleteButton = createMenuWidget("delete", journalEntryObj);
  journalEntryDiv.insertAdjacentElement("afterbegin", deleteButton);

  return journalEntryDiv;
}

export function meditationJournalPaneContainer({ row, meditation }) {
  /**

    this function does two things:
    1. Make a textarea to create new journal entries
    2. Populate it with today's journal entry for this meditation (but i seem to have broken that when I introduced uuid codes i think)

    - [] Extract the textArea functions so that they can be used for editing entries

  **/

  const maxLength = 5000;
  const meditationJournalContainer = document.createElement("div");
  meditationJournalContainer.classList.add("meditation_journal_container");
  meditationJournalContainer.innerHTML = `
    <div class='subheader'>Journal</div>
    <textarea class='meditation_journal' rows='8' maxLength='${maxLength}'></textarea>
  `;

  const textArea = meditationJournalContainer.querySelector(
    "textarea.meditation_journal",
  );

  // Limit textArea character input length
  ["input", "change"].forEach((listener) => {
    textArea.addEventListener(listener, () => {
      if (textArea.value.length > maxLength) {
        textArea.value = textArea.value.substring(0, maxLength);
      }
    });
  });

  /**

    Check Userdata for journal with this meditationId.
    If so, instantiate it, else create a new one.

  **/
  const existingEntry = window.Userdata.journalData.find({
    date: new Date(),
    meditationId: row.dataset.meditationId,
  });
  // console.log('Existing', existingEntry)

  const journalEntry = existingEntry
    ? new JournalEntry({ ...existingEntry })
    : new JournalEntry({
      text: "",
      meditationId: row.dataset.meditationId,
      meditationData: row.dataset,
    });

  if (existingEntry) {
    textArea.value = existingEntry.text;

    /**
      Omit displaying it in the previousEntries
    **/
  }

  // Store Journal entry on close
  // [] also store it periodically incase of crash etc. I believe
  // closing the pane/app instantly doesn't trigger blur.

  textArea.addEventListener("blur", () => {
    journalEntry.save(textArea.value);

    // saveNewJournalEntry(journalEntry);
    // saveNewJournalEntry(textArea.value, row.dataset.meditationId, row.dataset);
  });

  return meditationJournalContainer;
}

export function displayJournalEntries({
  journalEntries,
  target,
  link_to_meditation = true,
  omit = null,
}: {
  journalEntries: any,
  omit?: string | null
  target?: string,
  link_to_meditation?: boolean
}) {
  if (!journalEntries) return;

  if (!target) {
    target = document.querySelector(
      "section#journal div#meditation_journal_previous",
    );
  }

  // console.log('je', journalEntries);
  journalEntries.sort((a, b) => a.date < b.date);

  // Display all except this one
  if (omit) {
    journalEntries = journalEntries.filter((entry) => {
      // console.log('entry', entry);
      return entry.uuid !== omit.uuid;
    });

    console.log("filtered entries to display:", journalEntries);
  }

  if (journalEntries) {
    target.innerHTML = "";
    journalEntries.forEach((entry) => {
      if (!entry.hasOwnProperty("deleted")) {
        // console.log('display journal entry:', entry)
        const journalDiv = journalEntryComponentDiv(entry, link_to_meditation);
        target.appendChild(journalDiv);
      }
    });
  }
}

export function printEntriesList(journalEntries) {
  const entriesContainer = document.createElement("div");

  if (journalEntries.length) {
    entriesContainer.setAttribute("id", "meditation_journal_previous");
    entriesContainer.innerHTML = `<div class='subheader'>Previous Entries</div>`;

    displayJournalEntries({
      journalEntries: journalEntries,
      target: entriesContainer,
      link_to_meditation: false,
    });
  }

  return entriesContainer;
}

export function animateDeletingJournal(dataset) {
  document
    .querySelectorAll(`div.journal_entry[data-uuid='${dataset.uuid}']`)
    .forEach((journal) => {
      const journalHeight = journal.offsetHeight + "px";
      console.log("h", journalHeight);

      journal.style.setProperty("height", journalHeight);
      journal.style.height = journalHeight;

      setTimeout(() => {
        journal.dataset.delete = true;
      }, 100);

      function hideJournalFromDOM() {
        /**
        We animate the marginBottom collapsing, after the CSS transitions finish.
        This makes it smoother, and allows us to delete the element in a separate step after the marginBottom finishes.
      **/
        journal.style.setProperty("margin-bottom", "0", "important");
      }

      journal.addEventListener("transitionend", () => {
        hideJournalFromDOM();
        journal.removeEventListener("transitionend", hideJournalFromDOM);

        journal.addEventListener("transitionend", () => {
          journal.remove();
        });
      });
    });
}
