import { displayJournalEntries } from "../journal/journal_entries.ts";
// import { reconcileUserData } from "../classes/Userdata";
import { icons } from "../ui_general/icons.ts";
import { appState } from "../data/ar_globals";

if (!window.hasInitialisedPWA) {
  journalInit();
}

export async function journalInit() {
  // console.log('journalInit', 'token', appState.loginToken, 'first init')

  // Check if we've already synced from server at app-launch to avoid doing it again
  if (appState.loginToken && !appState.journalInit) {
    await window.Userdata.reconcileUserData("journal_data").then(
      (journalEntries) => {
        if (journalEntries) {
          // console.log('got new journal entries...', journalEntries);
          displayJournalEntries({
            journalEntries: journalEntries,
            target: previousEntriesDiv,
          });

          appState.journalInit = true;
        }
      },
    );
  }
}

export const sectionJournal = document.createElement("section");
sectionJournal.setAttribute("id", "journal");
sectionJournal.classList.add("membership", "hidden");
sectionJournal.innerHTML = `
  <div class="container">

    <div class="section_header">
      <div class='header_wrap'>
        <iconify-icon noobserver icon="${icons.postType.journal}"></iconify-icon>
        <div class='label'>Journal</div>
      </div>
    </div>

    <div class="contents">

      <div class="block" id="journal_entries.ts">
        <div class="subheader">Entries</div>
        <p>Journal entries from meditations you've listened to.</p>
        <div id="meditation_journal_previous"></div>
      </div>

      <div class="block" id="timeline" style='display: none'>
        <div class="subheader">Timeline</div>
        <p>
          Make a timeline of your trigger points and the work you are
          doing on it.
        </p>
        <p>
          Get summaries of the most salient features of your writing and
          what you are going through, to see it from a birds-eye
          perspective and know what is going on for you.
        </p>
        <p>
          Summary area for the month / quarter / year that summarises what
          you've been working on.
        </p>
        <p>Journal about your relationships and get a birds eye view.</p>
        <p>
          People with trauma overall need help creating coherent
          narratives for themselves. These summaries can help build that
          up, an externalised metacognition.
        </p>
        <p>Keyword-cloud showing the most common themes and issues.</p>
      </div>

    </div>
  </div>
`;

const previousEntriesDiv = sectionJournal.querySelector(
  "div#meditation_journal_previous",
);

document.addEventListener("section_journal", () => {
  if (appState.loginToken) {
    const journalEntries = JSON.parse(
      localStorage.getItem("AR_Userdata_journal_data"),
    );

    displayJournalEntries({ journalEntries: journalEntries });

    if (!appState.journalInit) {
      journalInit();
    }
  }
});

document.addEventListener("loginEvent", async () => {
  // console.log('journal heard login event');
  journalInit();
});

document.addEventListener("logoutEvent", () => {
  previousEntriesDiv.innerHTML = "";
});
