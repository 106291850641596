import { personalFieldsObj } from "../datastructures/personalFields.js";
import { updateUserData } from "../restapi/restapi_update_user.js";
import { icons } from "../ui_general/icons.ts";
import { issueTargetedBlock } from "../user/issueTargetedForm.js";

export function getPersonalFieldsFromLocalStorage() {
  const lsPersonalFields = localStorage.getItem("AR_Userdata_PersonalFields");
  return lsPersonalFields;
}

export function personalBlockCallback() {
  console.log("personalBlockCallback() open");

  // Set event listeners to save each field automatically
  Object.values(personalFieldsObj).forEach((field) => {
    const fieldElement = getFieldElement(field);
    fieldElement.element.addEventListener("blur", (element) => {
      // console.log('fieldBlur:', element.target);

      const formValues = readFormValues(personalFieldsObj);
      console.log("formValues to set", formValues);
      // localStorage.setItem('AR_Userdata_PersonalFields', JSON.stringify(formValues));
      window.Userdata.setCustomData("profileData", formValues);
    });
  });

  printPersonalFieldsFromLocalStorage();

  readIssueTargetedFieldsFromLocalStorage();

  function printPersonalFieldsFromLocalStorage() {
    // Load any present values out of Local Storage
    const storedPersonalData = window.Userdata.getCustomData("profileData");

    if (storedPersonalData) {
      // Write them into the document
      Object.values(storedPersonalData).forEach((domElement) => {
        if (domElement) {
          const fieldElement = getFieldElement(
            personalFieldsObj[domElement.key],
          );

          fieldElement.element.value = domElement.value;
        }
      });
    }
  }

  function readFormValues(fieldsObj) {
    const savedValues = Object.entries(fieldsObj).reduce((acc, field) => {
      const fieldObjKey = fieldsObj[field[0]];
      // console.log('readFormValues fieldObjKey:', fieldObjKey);

      const fieldElement = getFieldElement(fieldObjKey);
      acc[field[1].id] = {
        id: field[1].id,
        value: fieldElement.value,
        element: fieldElement.element,
        type: field[1].type,
        key: field[0],
      };
      return acc;
    }, {});

    return savedValues;
    // console.log('savedValues', savedValues);
  }
}

export function personalBlockClose() {
  if (window.Userdata.isLoggedIn) {
    const lsPersonalFields = getPersonalFieldsFromLocalStorage();

    // fetch request to post to server...
    // [] set up a route...
    // [] set up the acf...
    // [] write them to ACF...
  }

  personalBlock.querySelector("div.save_status_label").innerHTML = "";
}

export function getFieldElement(fieldDataKey) {
  // Receives a dataObj like 'pesonalFields'
  // console.log('getFieldElement', fieldDataKey)

  let element = undefined;
  switch (fieldDataKey.element) {
    case "input":
      element = "input";
      break;
    case "select":
      element = "select";
      break;
    case "textarea":
      element = "textarea";
      break;
  }

  const type = fieldDataKey.type ? `[type='${fieldDataKey.type}']` : "";
  // console.log(element, type);

  const fieldElement = document.querySelector(
    `div#personalBlock ${element}${type}`,
  );

  let value = undefined;
  switch (element) {
    case "input":
      value = fieldElement?.value ?? "";
      break;
    case "textarea":
      value = fieldElement?.value ?? "";
      break;
    case "select":
      value = fieldElement?.options[fieldElement.selectedIndex].value ?? "";
      break;
  }
  // console.log('value', value)

  return {
    element: fieldElement,
    value: value,
  };
}

function readIssueTargetedFieldsFromLocalStorage() {
  Object.keys(window.Userdata.profileData.psychology.issueTargeted).forEach(issue => {
    issueTargetedBlock.querySelector(`input#${issue}`).checked = true;
  })
}

/**
  Personal Block contains all the content on the 'Personal' slide pane.
*/
export const personalBlock = document.createElement("div");
personalBlock.classList.add("block_wrap");
personalBlock.setAttribute("id", "personalBlock");
personalBlock.innerHTML = `
  <div class="block" id="personal_block">
    <div class="subheader">Personal</div>
      <form id="personal_form">

        <fieldset id="nickname_set">
          <label>
            <div class='label'>Nickname</div>
            <input type="text" id="nickname"/>
          </label>
        </fieldset>

        <fieldset id="birthday_set">
          <label>
            <div class='label'>Birthday</div>
            <input type="date" id='birthday' min="1900-01-01" />
          </label>
        </fieldset>

        <fieldset id="gender_set">
          <label>
            <div class='label'>Gender</div>
            <div class='select_wrap'>
              <select id="gender_select">
                <option disabled selected value="">---</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="unspecified">Unspecified</option>
              </select>
            </div>
          </label>
        </fieldset>

        <fieldset id="bio_set" class='hidden'>
          <label>
            <div class='label'>Personal Bio</div>
            <textarea rows="8" id="personal_bio"></textarea>
          </label>
          <p class="minor">
            Information here will be included in the chat conversations.
          Add in important context, such as problems you are working on
          that the chatbot should always remember.
          </p>
        </fieldset>
      </form>
    </div>
  </div>`;

const saveButton = document.createElement("button-std");
saveButton.setAttribute("icon", icons.account);
saveButton.setAttribute("network", true);
saveButton.innerText = "Save";
saveButton.addEventListener("click", async () => {
  const localStoragePersonalFields = getPersonalFieldsFromLocalStorage();
  console.log(localStoragePersonalFields);

  const userData = {
    profile_data: localStoragePersonalFields,
  };

  // console.log('userData', userData)

  const response = await updateUserData(userData);

  if (response.status === 200) {
    saveButton.removeAttribute("disabled");
  }
  saveStatusLabel.innerText = response.message;
  saveStatusLabel.setAttribute("status_code", response.status);

  // const issuesT
});

/**
  Add issues targeted block to personalBlock

  1. X generate a form element
  2. X get the issues targeted list
  3. X iterate overit and print a checkbox for each one
  4. do it a custom way
    - label + checkbox
    - event listener on each label
    - userdata representation of these needs to be updated on each click.
    - then the userdata function to save these needs to be made sure to work properly
    - userdata can be responsible to store them in LS too.

*/
personalBlock.appendChild(issueTargetedBlock);

const personalForm = personalBlock.querySelector("form#personal_form");
personalForm.appendChild(saveButton);
const saveStatusLabel = document.createElement("div");
saveStatusLabel.classList.add("save_status_label");
saveStatusLabel.innerText = "";
personalForm.appendChild(saveStatusLabel);

// [x] function for a restButton:
// - disables the button until a response is had
// - re-enables after response has finished
// - has area to display response labels to the user
// [] load spinner
// [] make it generalisable

document.addEventListener("loginEvent", () => {
  if (document.querySelector("div.pane_wrap[data-pane-title=Personal]")) {
    console.log('try ok')
    personalBlockCallback();
    readIssueTargetedFieldsFromLocalStorage();
  }
});

document.addEventListener("logoutEvent", () => {
  personalForm.querySelector("input#nickname").value = "";
  personalForm.querySelector("input#birthday").value = "";
  personalForm.querySelector("select#gender_select").value = "";
  personalForm.querySelector("textarea#personal_bio").value = "";
});

// Fields
const attachment_style_fields = `<div class="block" style='display: none'>
  <div class="subheader">Attachment Style</div>
  <div class='select_wrap'>
    <select id="attachment_style_select">
      <option disabled selected>---</option>
      <option>Anxious / Preoccupied</option>
      <option>Avoidant / Dismissing</option>
      <option>Disorganized / Fearful-Avoidant</option>
    </select>
  </div>
</div>`;

const dmm_fields = `
  <div class="block" style='display: none'>
        <div class="subheader">Dynamic Maturational Model Type</div>
        <div class='select_wrap'>
          <select id="dmm_select">
            <option disabled selected>---</option>
            <optgroup label="Type A">
              <option>Idealizing [A1]</option>
              <option>Distancing [A2]</option>
              <option>Compulsive Caregiving &amp; Attention [A3]</option>
              <option>Compulsive Compliance &amp; Performance [A4]</option>
              <option>
                Compulsively Promiscuous (Socially / Sexually) [A5]
              </option>
              <option>
                Compulsively Self-Reliant (Social / Isolated) [A6]
              </option>
              <option>Delusional Idealization [A7]</option>
              <option>Externally Assembled Self [A8]</option>
            </optgroup>
            <optgroup label="Type B">
              <option>Balanced [B]</option>
            </optgroup>
            <optgroup label="Type C">
              <option>Threateningly Angry [C1]</option>
              <option>Disarmingly Desirous of Comfort [C2]</option>
              <option>Aggressively Angry [C3]</option>
              <option>Feigned Helpless [C4]</option>
              <option>Punitively Angry and Obsessed with Revenge [C5]</option>
              <option>Seductive and Obsessed with Rescue [C6]</option>
              <option>Menacing [C7]</option>
              <option>Paranoid [C8]</option>
            </optgroup>
            <optgroup label="Other">
              <option>Types A/C and AC</option>
              <option>Insecure Other [IO]</option>
            </optgroup>
          </select>
        </div>
      </div>
`;

const schema_fields = `
  <div class="block">
        <div class="subheader_area">
          <div class="subheader">Schemas</div>
          <form id="schema_form">
            <label>
              <input type="checkbox" id="schema_AB" name="schema">
              <span class='num'>1.</span>
              <div class='label'>Abandonment / Instability (AB)</div>
            </label>
            <label>
              <input type="checkbox" id="schema_MA" name="schema">
              <span class='num'>2.</span>
              <div class='label'>Mistrust / Abuse (MA)</div>
            </label>
            <label>
              <input type="checkbox" id="schema_ED" name="schema">
              <span class='num'>3.</span>
              <div class='label'>Emotional Deprivation (ED)</div>
            </label>
            <label>
              <input type="checkbox" id="schema_DS" name="schema">
              <span class='num'>4.</span>
              <div class='label'>Defectiveness / Shame (DS)</div>
            </label>
            <label>
              <input type="checkbox" id="schema_SI" name="schema">
              <span class='num'>5.</span>
              <div class='label'>Social Isolation / Alienation (SI)</div>
            </label>
            <label>
              <input type="checkbox" id="schema_DI" name="schema">
              <span class='num'>6.</span>
              <div class='label'>Dependence / Incompetence (DI)</div>
            </label>
            <label>
              <input type="checkbox" id="schema_VH" name="schema">
              <span class='num'>7.</span>
              <div class='label'>Vulnerability to Harm or Illness (VH)</div>
            </label>
            <label>
              <input type="checkbox" id="schema_EM" name="schema">
              <span class='num'>8.</span>
              <div class='label'>Enmeshment / Undeveloped Self (EM)</div>
            </label>
            <label>
              <input type="checkbox" id="schema_FA" name="schema">
              <span class='num'>9.</span>
              <div class='label'>Failure to Achieve (FA)</div>
            </label>
            <label>
              <input type="checkbox" id="schema_ET" name="schema">
              <span class='num'>10.</span>
              <div class='label'>Entitlement / Grandiosity (ET)</div>
            </label>
            <label>
              <input type="checkbox" id="schema_IS" name="schema">
              <span class='num'>11.</span>
              <div class='label'>Insufficient Self-Control/Discipline (IS)</div>
            </label>
            <label>
              <input type="checkbox" id="schema_SB" name="schema">
              <span class='num'>12.</span>
              <div class='label'>Subjugation (SB)</div>
            </label>
            <label>
              <input type="checkbox" id="schema_SS" name="schema">
              <span class='num'>13.</span>
              <div class='label'>Self-Sacrifice (SS)</div>
            </label>
            <label>
              <input type="checkbox" id="schema_AS" name="schema">
              <span class='num'>14.</span>
              <div class='label'>Approval/Recognition Seeking (AS)</div>
            </label>
            <label>
              <input type="checkbox" id="schema_NP" name="schema">
              <span class='num'>15.</span>
              <div class='label'>Negativity / Pessimism (NP)</div>
            </label>
            <label>
              <input type="checkbox" id="schema_EI" name="schema">
              <span class='num'>16.</span>
              <div class='label'>Emotional Inhibition (EI)</div>
            </label>
            <label>
              <input type="checkbox" id="schema_US" name="schema">
              <span class='num'>17.</span>
              <div class='label'>Unrelenting Standards / Hypercritical (US)</div>
            </label>
            <label>
              <input type="checkbox" id="schema_PU" name="schema">
              <span class='num'>18.</span>
              <div class='label'>Punitiveness (PU)</div>
            </label>
          </form>
        </div>

        <div class='block' style='display: none'>
          <div class="progress_bar">
            <div class="label">Abandonment</div>
            <div class="bar"></div>
          </div>
          <div class="progress_bar">
            <div class="label">Abandonment</div>
            <div class="bar"></div>
          </div>
          <div class="progress_bar">
            <div class="label">Abandonment</div>
            <div class="bar"></div>
          </div>
          <div class="progress_bar">
            <div class="label">Abandonment</div>
            <div class="bar"></div>
          </div>
        </div>
      </div>
`;

const coping_modes_fields = `
  <div class="block">
    <div class="subheader">Coping Modes</div>
      <form id="coping_modes_form">
        <label>
          <input type="checkbox" id="mode_VC" name="schema">
          <span class='num'>1.</span>
          <div class='label'>Vulnerable Child mode</div>
        </label>
        <label>
          <input type="checkbox" id="mode_CS" name="schema">
          <span class='num'>1.</span>
          <div class='label'>Compliant Surrender mode</div>
        </label>
        <label>
          <input type="checkbox" id="mode_DS" name="schema">
          <span class='num'>1.</span>
          <div class='label'>Detached Surrender mode</div>
        </label>
      </form>
  </div>
  `;
