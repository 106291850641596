import { formatHHMMSSToSeconds } from "../ui_general/ar_utils.ts";

export class MeditationTranscript {
  constructor(meditationId, transcript = null) {
    this.meditationId = meditationId;
    this.text = transcript;
  }

  get htmlSegmentsArray() {
    return this.#transcriptToHTML();
  }

  #transcriptToHTML() {
    // Returns an array of segment objects

    const transcriptSegmentsArray = this.#generateTranscriptObj().map(
      (segment) => {
        const div = document.createElement("div");
        div.classList.add("segment");
        div.dataset.timestamp = segment.timestamp;
        div.dataset.seconds = segment.seconds;
        div.innerText = segment.text;
        return div;
      },
    );

    return transcriptSegmentsArray;
  }

  #generateTranscriptObj() {
    // need to read it out of LS for the case of favourited ones

    if (!this.text) return [];

    const splitLines = this.text.split(/<br \/>/);
    const filteredLines = splitLines.filter((line) => !line.match(/^\r\n$/g));

    // Convert lines into transcript segment objects.
    const mappedArray = filteredLines.reduce((acc, line, index) => {
      // console.log(index, 'line', line, filteredLines[index + 1]);

      if (index % 2 === 0) {
        // Can match both 00:00 and 00:00:00 formats
        const thisSegmentStartTime =
          filteredLines[index + 1].match(/\d{2}:\d{2}(:\d{2})?/)[0];
        const thisSegmentEndTime = "";
        const prevSegmentEndTime = "";
        let segment = {};

        if (
          this.#secondsBetweenTimestamps(
            thisSegmentStartTime,
            prevSegmentEndTime,
          ) > 5
        ) {
          // If startTime is more than x seconds (5? 10?) from previous endTime,
          // we create an 'empty space' segment.
          segment = {
            type: "space",
            timeStart: "",
            timeEnd: "",
            seconds: "",
          };
        } else {
          // Create a text segment.
          let text = line.replace("\r\n", "");

          /*
            Lowercase-starting lines are continuations of another line, so
            we prepend '...' to indicate that
          */
          if (text[0] === text[0].toLowerCase()) {
            text = "..." + text;
          }

          segment = {
            text: text,
            timestamp: thisSegmentStartTime,
            timeStart: thisSegmentStartTime,
            timeEnd: thisSegmentEndTime,
            seconds: formatHHMMSSToSeconds(thisSegmentStartTime),
            type: "", // future uses: 'text|silence|begin|end|section| etc...'
          };
        }
        acc.push(segment);
      }
      return acc;
    }, []);

    // console.log('tt', transcriptText)
    // console.log('lines', splitLines);
    // console.log('fl', filteredLines);
    console.log("mapped", mappedArray);

    return mappedArray;
  }

  #secondsBetweenTimestamps(thisSegmentStartTime, prevSegmentEndTime) {
    return 0;
  }
}
