/**
  This file contains third party dependencies that the app relies on
*/
import "./app/ar_dependencies.js";

/**
  Global functions and variables that are used throughout the app
*/
import * as ar from "./data/ar_globals.js";

/**
  This module automatically captures any JS console errors and sends them to the server, which forwards them to our Telegram "PWA Errors" channel.
*/
import "./app/error_logging.js";

// PWA specific
import "./app/pwa_install.js";
import "./app/service_worker_install.js";

// Deving
import "./ui_general/debug_box.js";

// Sections
import { sectionStart } from "./sections/section_start.js";
import { sectionAssessments } from "./sections/section_assessments.js";
import { sectionChat } from "./sections/section_chat.js";
import { sectionJournal } from "./sections/section_journal.js";
import { sectionCourses, spcLibraryInit } from "./sections/section_courses.js";
import {
  sectionMeditation,
  meditationLibraryInit,
} from "./sections/section_meditation.js";
import { sectionProfile } from "./sections/section_profile.js";

// Specific UI
import { setUILoggedIn, setUILoggedOut } from "./ui_components/login_form.js";
import { mainMenu } from "./ui_components/membership_menu.js";
import { safeArea } from "./ui_components/safearea.js";

// Load Components
import "./ui_components/button_std.js";
import "./ui_components/button_arrow.js";
import "./ui_components/button_view.js";
import "./ui_components/completion_bar.js";

// Network
import { validateLocalJWT } from "./restapi/restapi_login_user.js";
import { getTaxonomyDataFromRestApi } from "./restapi/restapi_requests.js";

if (!window.hasInitialisedPWA) {
  console.info("Vite import.meta.env", import.meta.env);
  console.info(
    "Loaded: ⭐ ar_pwa.js",
    "📱 isTouchDevice(): ",
    ar.isTouchDevice(),
  );
  console.info("⚙️ Dev Settings ", ar.settings);
  console.info("Userdata ", window.Userdata);

  /**

    Misc

  **/
  getTaxonomyDataFromRestApi();

  (function checkIfUserIsAlreadyAuthenticated() {
    const localStorageJWT = window.Userdata.jwt;
    // console.log('localStorageJWT', localStorageJWT);

    if (localStorageJWT) {
      setUILoggedIn();
    }

    validateLocalJWT(localStorageJWT)
      .then((tokenResponse) => {
        if (tokenResponse.data?.status === 200) {
          // console.log('validateLocalJWT(): Already logged in...');
          window.Userdata.isLoggedIn = true;
        } else {
          // Set to no account
          setUILoggedOut();
        }
      })
      .catch((error) => {
        console.error("validateLocalJWT(): ", error);
      });
  })();

  (function initServiceWorker() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("./service-worker.js").then(
        (registration) => {
          console.info(
            "ServiceWorker registration successful with scope:",
            registration.scope,
          );
        },
        (error) => {
          console.error("ServiceWorker registration failed:", error);
        },
      );
    }
  })();

  window.hasInitialisedPWA = true;
}

document.addEventListener("DOMContentLoaded", async function onLoad() {
  if (window.hasLoadedDOM === true) return;
  window.hasLoadedDOM = true;

  viewPort.append(sectionStart);
  viewPort.append(sectionProfile);
  viewPort.append(sectionAssessments);
  viewPort.append(sectionChat);
  viewPort.append(sectionCourses);
  viewPort.append(sectionJournal);
  viewPort.append(sectionMeditation);
  viewPort.append(safeArea);
  viewPort.append(mainMenu);

  meditationLibraryInit();
  spcLibraryInit();

  (function checkAnchorAndFireTabSelector() {
    const anchor = window.location.hash.substring(1);
    if (anchor) {
      const section = document.querySelector(`section.membership#${anchor}`);

      if (section) {
        mainMenu.querySelector(`div.tab[data-membership='${anchor}']`).click();
      } else {
        mainMenu.querySelector(`div.tab[data-membership='home']`).click();
      }
    }
  })();

  // These need to be called independently in each section that they are used in.
  (function initButtonViewSet() {
    // console.log('button view set funct init');

    document.querySelectorAll("section.membership").forEach((section) => {
      // console.log("sections:", section);
      section.querySelectorAll("div.button_view_set").forEach((set) => {
        // Get all buttons and blocks in set
        const button_wraps = Array.from(set.children);
        const blocks = section.querySelectorAll("div.block.view_set");
        // console.log("button_wraps", button_wraps);
        // console.log("blocks", blocks);

        button_wraps.forEach((button_view_wrap) => {
          // console.log("button view", button_view_wrap);

          const button_name = button_view_wrap.attributes["data-name"].value;
          // console.log("-- name:", button_name);

          button_view_wrap.addEventListener("click", () => {
            // Toggle class ".current" to buttons
            button_wraps.forEach((button) =>
              button.classList.remove("current"),
            );
            button_view_wrap.classList.add("current");

            localStorage.setItem(
              `AR_Userdata_Settings_${section.id}_buttonView_current`,
              button_name,
            );

            // Toggle class ".current" to blocks
            blocks.forEach((block) => {
              // console.log('blocks', block)
              if (block.id === button_name) {
                block.classList.add("current");
              } else {
                block.classList.remove("current");
              }
            });
          });
        });
      });

      // Read last used section out of Local Storage
      const currentButtonView = localStorage.getItem(
        `AR_Userdata_Settings_${section.id}_buttonView_current`,
      );
      if (currentButtonView) {
        const currentButton = document.querySelector(
          `section#${section.id} div.button_view_wrap[data-name=${currentButtonView}]`,
        );
        currentButton?.click();
      }
    });
  })();
});
