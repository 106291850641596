export function billingBlockCallback() {}

export const billingBlock = document.createElement("div");
billingBlock.classList.add("block_wrap");
billingBlock.setAttribute("id", "billingBlock");
billingBlock.innerHTML = `(blank)`;
// billingBlock.innerHTML = `
//   <stripe-pricing-table pricing-table-id="prctbl_1PSHXxGb2maKUphthKtRGGDK"
//   publishable-key="pk_live_BuSTi7etaksaoLEjk64lVYw500IRkHaUcM">
//   </stripe-pricing-table>
//   `;
