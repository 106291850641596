import { authHeader } from "../restapi/restapi_login_user.js";
import {
  enabledMeditationTaxonomies,
  enabledMeditationFilters,
  restApiHostUrl,
} from "../data/ar_globals";

export function apiURL(service = "ar", endpoint) {
  const baseUrl = restApiHostUrl;
  const restPath = {
    ar: `wp-json/ar/v1/pwa`,
    mkt: `wp-json/ar/v1/mkt`,
    wp: `wp-json/wp/v2`,
    jwt: `wp-json/jwt-auth/v1`,
    woo: `wc-api/v3`,
  };

  if (!restPath[service]) {
    console.error("apiURL(): invalid parameter 'service':", service);
    return;
  }

  const url = new URL(`${baseUrl}/${restPath[service]}/${endpoint}`);
  // console.log('apiURL()', url);

  return url;
}

// [] Pass in enabledMeditationFilters as another parameter here.

export async function restAPIRequest(argConfig = {}) {
  const defaultConfig = {
    postType: "meditation",
    page: 1,
    postsPerPage: 20,
  };

  let postTypeConfig = {};

  if (argConfig.postType === "meditation") {
    postTypeConfig = {
      ...enabledMeditationFilters.reduce((acc, taxonomyName) => {
        acc[taxonomyName] = argConfig[taxonomyName];
        return acc;
      }, {}),
    };
  }

  const config = {
    ...defaultConfig,
    ...argConfig,
    ...postTypeConfig,
  };

  // console.log('restAPIRequest() initial argConfig', argConfig)
  // console.log('reduced argConfig', argConfig)
  // console.log('postTypeConfig', postTypeConfig)
  // console.log('defaultConfig')
  // console.log('final config', config)

  // console.log("🛜 restAPIRequest: ", config);

  try {
    const url = apiURL("ar", config.postType);
    url.searchParams.append("page", config.page.toString());
    url.searchParams.append("per_page", config.postsPerPage.toString());

    if (config.postType === "meditation") {
      enabledMeditationFilters.forEach((taxonomyName) => {
        if (config[taxonomyName]) {
          url.searchParams.append(
            taxonomyName,
            config[taxonomyName].toString(),
          );
        }
      });
    }

    // For testing:
    // if (window.Userdata.isLoggedIn === false) {
    //   url.searchParams.append("membership_level", "tier_free");
    // }

    const response = await fetch(url, {
      headers: {
        Authorization: authHeader(),
      },
    });

    const data = await response.json();

    if (!response.ok) {
      if (response.status === 403) {
        console.error("REST API forbidden... removing JWT from localStorage");
        window.Userdata.jwt = null;
      }
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    // return data;

    const totalPages = Number(response.headers.get("X-WP-TotalPages"));
    // console.log('🛜 restAPIRequest Total pages:', totalPages);

    return {
      posts: data,
      totalPages: totalPages,
      page: config.page,
    };
  } catch (error) {
    console.error("Failed to fetch posts:", error);
    throw error;
  }
}

export async function getTaxonomyDataFromRestApi() {
  /**

    This function gets the taxonomy data from the server and stores it locally for use throughout the rest of the UI.

    Gets called upon app launch, then stored in Local Storage.

  **/

  enabledMeditationTaxonomies.forEach((taxonomySlug) => {
    (async () => {
      try {
        const response = await fetch(
          apiURL("mkt", `taxonomy?slug=${taxonomySlug}`),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        if (!response.ok) {
          console.error(
            "getTaxonomyDataFromRestApi()",
            response.status,
            response.statusText,
          );
          return;
        }

        const result = await response.json();
        if (result) {
          // console.log('✅ getTaxonomyDataFromRestApi:', result);
          localStorage.setItem(
            `AR_Appdata_TaxonomyData.slug.${taxonomySlug}`,
            JSON.stringify(result),
          );
        }
      } catch (error) {
        console.error("Error fetching taxonomy data:", error);
      }
    })();
  });
}
