import { icons } from "../ui_general/icons.ts";

(function () {
  class ButtonView extends HTMLElement {
    static observedAttributes = ["color", "size"];

    constructor() {
      super();

      this.attachShadow({ mode: "open" });
      this.shadowRoot.innerHTML = `
        <style>
        button {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          column-gap: var(--z2);
          align-items: center;

          padding: var(--z2) var(--z6);
          margin: 6px;

          appearance: none;

          background: hsl(215.7,100%,92.7%);

          border-color: var(--c-lavendar3);
          border-style: solid;
          border-width: 0 0 2px;
          border-radius: var(--b1);
          border-bottom-color: var(--c-violet4);

          color: var(--c-lavendar9);
          font: inherit;

          &[disabled] {
            filter: grayscale(0.5);
            cursor: not-allowed;
            opacity: 0.5;
          }

          div.label {
            font-size: 14px;
          }

          iconify-icon {
            font-size: 24px;
            opacity: 0.75;
          }
        }
      </style>

      <button color='red' size='big' onclick=''>
        <iconify-icon noobserver icon='${icons.postType.chat}'></iconify-icon>
        <div class='label'>New Chat</label>
      </button>
        `;
    }

    connectedCallback() {
      console.log("Custom element added to page.");
    }

    disconnectedCallback() {
      console.log("Custom element removed from page.");
    }

    adoptedCallback() {
      console.log("Custom element moved to new page.");
    }

    attributeChangedCallback(name, oldValue, newValue) {
      console.log(`Attribute ${name} has changed.`);
    }
  }

  customElements.define("ar-button-view", ButtonView);
})();

function buttonViewStateHandler() {
  // Pass in list of buttons
  // Add event handler to each: if tapped, toggle state '.current_view'
  // Toggle .current_view on respective blocks
  // Only searches locally within the current <section> or smaller
}
