export const terms_purpose = [
  {
    name: "Education",
    slug: "education",
    count: 52,
    description:
      "Meditations that also contain psycho-educational material that can be useful for doing healing work",
    id: 171,
    parent: 0,
  },
  {
    name: "Healing",
    slug: "healing",
    count: 461,
    description:
      "For doing the actual work of healing, re-patterning, transforming, and working with deep conditioning in order to produce lasting change.",
    id: 157,
    parent: 0,
  },
  {
    name: "Positive Qualities",
    slug: "healthy-adult-mode",
    count: 240,
    description:
      "For generating the positive character qualities and vision for a new way to show up in life - exploration, healthy adult development, and virtue development.",
    id: 158,
    parent: 0,
  },
  {
    name: "Self Awareness",
    slug: "metacognition",
    count: 412,
    description:
      "For studying your mind and gaining insight and self-awareness as to what are some of the patterns and conditions that are occurring.",
    id: 156,
    parent: 0,
  },
  {
    name: "Soothing",
    slug: "soothing",
    count: 296,
    description:
      "For getting the mind into a state where it is less reactionary and more receptive to doing introspective and healing work.",
    id: 155,
    parent: 0,
  },
  {
    name: "Working Through Danger",
    slug: "working-through-danger",
    count: 0,
    description: "To process past experiences of danger",
    id: 268,
    parent: 0,
  },
];
