import * as ui from "../ui_general/create_menu";
import * as tippy from "../ui_general/tippy.ts";
import { icons } from "../ui_general/icons.ts";
import {
  meditationMenu,
  deleteMenu,
  spcMenu,
} from "../ui_general/menu_definitions.js";

export function createMenuWidget(type = "default", data = {}, orientation) {
  const menuButton = createMenuButton(type, orientation, data);

  switch (type) {
    case "meditation":
      attachMeditationRowMenu(menuButton, data);
      break;
    case "spc":
      attachSPCRowMenu(menuButton, data);
      break;
    case "delete":
      attachDeleteMenu(menuButton, data);
      break;
  }

  return menuButton;
}

export function createMenuButton(type = "default", orientation = "vertical") {
  const icon = {
    meditation: icons.menuDots,
    spc: icons.menuDots,
    delete: icons.bin,
    default: icons.menuDots,
  };

  const menuWidget = document.createElement("div");
  menuWidget.classList.add("menu_widget");
  menuWidget.innerHTML = `
    <iconify-icon noobserver icon='${icon[type]}' class='${type} ${orientation}'></iconify-icon>
  `;

  return menuWidget;
}

export function attachDeleteMenu(target, dataset) {
  if (!target._tippy) {
    const tippyInstance = tippy.initialiseTippyOn({
      element: target,
      theme: "menu",
      placement: "top",
      allowHTML: true,
      offset: [-20, 10],
      onShow(instance) {
        const menuItems = deleteMenu(dataset, tippyInstance);
        const menuObj = new ui.CreateMenu(menuItems);
        instance.setContent(menuObj.menu);
      },
    });
    return tippyInstance;
  }
}

export function attachMeditationRowMenu(target, dataset) {
  if (!target._tippy) {
    const tippyInstance = tippy.initialiseTippyOn({
      element: target,
      theme: "menu",
      placement: "left",
      allowHTML: true,
      offset: [0, 10],
      onShow(instance) {
        const menuItems = meditationMenu(dataset);
        const menuObj = new ui.CreateMenu(menuItems);
        instance.setContent(menuObj.menu);
      },
    });
    return tippyInstance;
  }
}

export function attachSPCRowMenu(target, dataset) {
  if (!target._tippy) {
    const tippyInstance = tippy.initialiseTippyOn({
      element: target,
      theme: "menu",
      placement: "left",
      allowHTML: true,
      offset: [0, 10],
      onShow(instance) {
        const menuItems = spcMenu(dataset);
        const menuObj = new ui.CreateMenu(menuItems);
        instance.setContent(menuObj.menu);
      },
    });
    return tippyInstance;
  }
}
