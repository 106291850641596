export function termSlugToName(taxSlug, termSlug) {
  const taxObject = JSON.parse(
    localStorage.getItem(`AR_Appdata_TaxonomyData.slug.${taxSlug}`),
  );
  const readableSlug = termSlug.replace(/\-/g, " ");

  if (!taxObject) return readableSlug;

  const term = taxObject.find((term) => term.slug === termSlug);
  return term?.name || readableSlug;
}
