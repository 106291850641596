import { appState } from "../data/ar_globals";
import { logTheUserIn } from "../restapi/restapi_login_user.js";
import { icons } from "../ui_general/icons.ts";

// Components
// Button for Login
export const loginButton = document.createElement("button-std");
loginButton.setAttribute("id", "login_submit");
loginButton.setAttribute("hue", 210);
loginButton.setAttribute("icon", icons.login);
loginButton.textContent = "Login";

// Input for Email
export const emailForm = document.createElement("input");
emailForm.setAttribute("type", "email");
emailForm.setAttribute("id", "login_email");
emailForm.setAttribute("placeholder", "Email Address");
emailForm.setAttribute("autocomplete", "email");

// Div for Logged In User's Name
export const loggedUserName = document.createElement("div");
loggedUserName.setAttribute("id", "logged_user_name");
loggedUserName.classList.add("hidden");

// Input for Password
export const passwordForm = document.createElement("input");
passwordForm.setAttribute("type", "password");
passwordForm.setAttribute("id", "login_password");
passwordForm.setAttribute("placeholder", "Password");
passwordForm.setAttribute("autocomplete", "current-password");

// Button for Logout
export const logoutButton = document.createElement("button-std");
logoutButton.setAttribute("id", "logout_submit");
logoutButton.setAttribute("icon", icons.logout);
logoutButton.classList.add("hidden");
logoutButton.textContent = "Logout";

export const loginStatusText = document.createElement("div");
loginStatusText.setAttribute("id", "login_status_text");

// Form to hold it all together
export const loginForm = document.createElement("div");
loginForm.setAttribute("id", "login_box");
loginForm.classList.add("active");
loginForm.append(
  loggedUserName,
  emailForm,
  passwordForm,
  loginButton,
  logoutButton,
  loginStatusText,
);

// Functionality
loginButton.addEventListener("keypress", (event) => keyEnterLogin(event));

loginButton.addEventListener("click", () => {
  document.querySelector("div#login_status_text").innerText = "Logging in...";

  logTheUserIn(emailForm.value, passwordForm.value)
    .then((tokenResponse) => {
      if (tokenResponse.data.status === 200) {
        // console.log('logTheUserIn() tokenResponse:', tokenResponse)
      } else {
        // console.log('Not logged in');
        // Set default level
      }
    })
    .finally(() => {
      //
    })
    .catch((error) => {
      console.error("logTheUserIn(): ", error);
      loginStatusText.innerText = "Log in Failed via Form";
    });
});

export function keyEnterLogin(event) {
  if (event.key == "Enter") {
    // console.log('Enter: login form')
    loginButton.click();
  }
}

emailForm.addEventListener("keypress", (event) => keyEnterLogin(event));

passwordForm.addEventListener("keypress", (event) => keyEnterLogin(event));

logoutButton.addEventListener("click", () => {
  // Dispatch logoutEvent
  const logOutEvent = new CustomEvent("logoutEvent", {
    bubbles: true,
    composed: true,
  });

  document.dispatchEvent(logOutEvent);
});

document.addEventListener("loginEvent", function (loginEvent) {
  const { isLoggedIn } = loginEvent.detail;
  if (isLoggedIn) {
    console.log("🚪✅ User is logged in");
    setUILoggedIn();
  } else {
    console.log("🚪❌ User is not logged in");
  }
});

document.addEventListener("logoutEvent", function (logoutEvent) {
  console.log("🚪❌ User is logged out");
  setUILoggedOut();
});
// UI STUFF

export function setUILoggedIn() {
  appState.loginToken = true;
  document.body.dataset["loggedIn"] = "true";

  const userName = window.Userdata.getWpData("name") ?? "";
  // [] put a membership account pill here

  // Clear values
  emailForm.value = "";
  passwordForm.value = "";
  loginStatusText.innerText = "";

  // Hide
  emailForm.classList.add("hidden");
  passwordForm.classList.add("hidden");
  loginButton.classList.add("hidden");

  // Show
  logoutButton.classList.remove("hidden");
  loggedUserName.classList.remove("hidden");
  loggedUserName.innerHTML = `<span class='welcome'>Welcome</span> ${userName}`;

  document.querySelector("div#login_tab")?.click();
}

export function setUILoggedOut() {
  appState.loginToken = false;
  document.body.dataset["loggedIn"] = "false";

  // Hide
  logoutButton.classList.add("hidden");
  loggedUserName.classList.add("hidden");

  // Show
  emailForm.classList.remove("hidden");
  loginButton.classList.remove("hidden");
  passwordForm.classList.remove("hidden");
}
