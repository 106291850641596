import { getEnvironment } from "../data/ar_globals.js";
import { roundArrow } from "tippy.js";
import type { TippyParams } from "../types/tippyParams.d.ts";

/**
  Global Tippy variables
*/
window.isDesktop = window.matchMedia("(min-width: 768px)").matches;
window.deviceTrigger = window.isDesktop ? "mouseenter" : "click";
window.deviceTriggerEnv =
  getEnvironment() === "Production" ? window.deviceTrigger : "click";
window.initialiseTippyOn = initialiseTippyOn;

// console.log('getEnvironment', getEnvironment())
// console.log('window.deviceTriggerEnv', window.deviceTriggerEnv);

const appwideTippySettings = {
  delay: 0,
  interactive: true,
  arrow: roundArrow,
  inertia: true,
};

/**
 * Initializes Tippy tooltips on specified elements
 * @paramsObj Configuration options for Tippy tooltips
 */
export function initialiseTippyOn(paramsObj: TippyParams) {
  const selector =
    paramsObj.element ?? document.querySelectorAll(paramsObj.selector);
  // console.log('Tippy selector:', selector)

  const defaultOnCreate = (instance) => {
    instance.reference.addEventListener("click", (event) => {
      event.stopPropagation();
    });
  };

  const defaultOnMount = (instance) => {
    instance.popper.addEventListener("click", (event) => {
      event.stopPropagation();
    });
  };

  return tippy(selector, {
    ...appwideTippySettings,
    trigger: paramsObj.trigger || window.deviceTriggerEnv,
    theme: paramsObj.theme,
    placement: paramsObj.placement || "top",
    allowHTML: paramsObj.allowHTML || false,
    content: paramsObj.content,
    offset: paramsObj.offset,
    onShow: paramsObj.onShow,
    onHidden: paramsObj.onHidden,
    hideOnClick: paramsObj.hideOnClick || true,
    animation: paramsObj.animation || "scale",
    duration: paramsObj.duration || [100, 100],
    onCreate: (instance) => {
      defaultOnCreate(instance);
      if (paramsObj.onCreate) {
        paramsObj.onCreate(instance);
      }
    },
    onMount: (instance) => {
      defaultOnMount(instance);
      if (paramsObj.onMount) {
        paramsObj.onMount(instance);
      }
    },
  });
}
