import { sectionStart } from "../sections/section_start";
import { userDevice } from "../data/ar_globals";

document.addEventListener("DOMContentLoaded", () => {
  // console.log('pwa_install.js()')

  window.addEventListener("beforeinstallprompt", (event) => {
    console.log("beforeinstallprompt fired");
    sectionStart
      .querySelector("div#install_app")
      .insertAdjacentHTML("afterbegin", `<p>💡 App can be installed</div>`);

    event.preventDefault();
    installPrompt = event;
  });

  /**
    Get User Device
  **/

  sectionStart.querySelector("div#user_device").innerText =
    "🖥️ Your device: " + userDevice();

  /**
    Install Button
  **/
  const installButton = sectionStart.querySelector("div#install_button");
  let installPrompt = undefined;

  installButton.addEventListener("click", async () => {
    console.log("click");
    if (!installPrompt) {
      console.log("No installPrompt...");
      sectionStart.querySelector("div#install_prompt_status").innerText =
        "No install prompt...";
      return;
    }
    const result = await installPrompt.prompt();
    console.log(`Install prompt was: ${result.outcome}`);
    sectionStart.querySelector("div#install_prompt_status").innerText =
      `Install result: ${result.outcome}`;
    disableInAppInstallPrompt();
  });

  function disableInAppInstallPrompt() {
    installPrompt = null;
    installButton.setAttribute("hidden", "");
  }

  window.addEventListener("appinstalled", () => {
    console.log("App installed, removing install button");
    sectionStart.querySelector("div#install_app").innerHTML = "✅ Installed";
  });
});
