import { accountForm } from "../ui_components/account_form.js";
import { collapseBox } from "../ui_components/collapse_box.js";
import { changeLogContent } from "../data/changelog.js";
import { userDevice } from "../data/ar_globals";

export const sectionStart = document.createElement("section");
sectionStart.setAttribute("id", "home");
sectionStart.classList.add("membership");
sectionStart.innerHTML = `
  <div class="container">

    <div class='contents'>

      <div id="start_header">
        <div id='main_header'>
          <img id="ar_logo" src="svg/logo128.svg" />
          <div id='ar_title'>Attachment&nbsp;Repair Membership</div>
        </div>
        <div id='slogan'>Self-paced Depth Psychology</div>
      </div>

      <div id="account_entry_form_container" class="block_container">
      </div>

      <div class="block_container" id="install_app">
        <div style='display: none'>
        <h2>Install Membership App</h2>

        <div id='status_divs'>
          <div id='user_device' class='status'></div>
          <div id='install_prompt_status' class='status'></div>
          <div id='service_worker_status' class='status'></div>
        </div>

        <div id='install_button' class='button'>📲 Install App</div>
        </div>

        <div id='instructions_ios' class='instructions'></div>
        <div id='instructions_android' class='instructions'></div>
        <div id='instructions_desktop' class='instructions'></div>

      </div>

      <div class="block_container" id="resume_block" style='display: none'>
        <h2>Resume</h2>
        <p>...</p>
      </div>

      <div class="block_container" id="new_content_block" style='display: none'>
        <h2>New Content</h2>
        <p style='display: none'>Display latest 3 SPCs and latest 5 meditations</p>
      </div>

      <div class="block_container" id="changelog_block">
        <h2>Updates</h2>

        <div id="changelog_content">
          ${changeLogContent}
        </div>

      </div>

      <div class="block_container" id="feedback_block">
        <h2>Feedback</h2>
        <p>Please fill in our <a target='_blank' href='https://forms.gle/ZEv4SEJdqEYyxM769'>Feedback form</a>!</p>
      </div>

    </div>
  </div>
  `;

document.addEventListener("DOMContentLoaded", () => {
  "";
  // Hide app loading widget
  document.getElementById("app_loading").classList.add("off");

  sectionStart
    .querySelector("div#account_entry_form_container")
    .insertAdjacentElement("afterbegin", accountForm);

  switch (userDevice()) {
    case "iOS": {
      sectionStart.querySelector("div#instructions_ios").innerHTML =
        collapseBox({
          name: "instructions_ios",
          id: "instructions_ios",
          label: "How to install the app on iOS (Safari)",
          contents: htmlInstructionsiOS,
        });
      break;
    }
    case "Android": {
      sectionStart.querySelector("div#instructions_android").innerHTML =
        collapseBox({
          name: "instructions_android",
          id: "instructions_android",
          label: "How to install the app on Android (Chrome)",
          contents: htmlInstructionsAndroid,
        });
      break;
    }
    default: {
      sectionStart.querySelector("div#instructions_desktop").innerHTML =
        collapseBox({
          name: "instructions_desktop",
          id: "instructions_desktop",
          label: "How to install the app on Desktop",
          contents: htmlInstructionsDesktop,
        });
      break;
    }
  }
});

const htmlInstructionsiOS = `
  <p>1. Open the share menu</p>
  <img src='images/pwa_instructions/ios1.webp' alt="1. Open the share menu" class='instructions' />

  <p>2. Click the 'Add to Home Screen' menu item</p>
  <img src='images/pwa_instructions/ios2.webp' alt="2. Click the 'Add to Home Screen' menu item" class='instructions' />
  `;

const htmlInstructionsAndroid = `
  <p>1. Open the main menu</p>
  <img src='images/pwa_instructions/android1.webp' alt="1. Open the main menu" class='instructions' />

  <p>2. Click the 'Add to Home Screen' menu item</p>
  <img src='images/pwa_instructions/android2.webp' alt="2. Click the 'Add to Home Screen' menu item" class='instructions' />
  `;

const htmlInstructionsDesktop = `
  <div style='display: none'>
  <p>The app isn't available yet in app stores for desktop installation. Installation will depent on the browser you use:</p>
  <p>Brave: mac (⌘+⇧+P) or windows (⌃+⇧+P) to open Quick Command, then type "Install PWA"</p>
  <p>Chrome</p>
  <p>Safari</p>

  <p>Other: you'll need to look up 'How to install a PWA in (your browser)'</p>
  </div>
  `;
