import "iconify-icon";
import { icons } from "../ui_general/icons.ts";

customElements.define(
  "loading-spinner",
  class LoadingSpinner extends HTMLElement {
    static get observedAttributes() {
      return ["color", "size", "label", "icon", "onclick"]; // Add 'icon' and 'onclick' to the list
    }

    constructor() {
      super();

      this.attachShadow({ mode: "open" });
      this.shadowRoot.innerHTML = `
				<style>
				  div.loading_spinner {
						--height: 80px;
					  --width: 80px;
					  --bg-colour: hsla(217, 100%, 78%, 0.75);

				    position: fixed;
				    top: calc(50% - var(--height) / 2);
				    left: calc(50% - var(--width) / 2);
				    z-index: 10000;
				    background: var(--bg-colour);
				    box-shadow: 0 0 20px 4px var(--bg-colour);
				    border-radius: 12px;
				    color: white;
				    width: var(--width);
				    height: var(--height);
				    display: flex;
				    justify-content: center;
				    align-items: center;
				    font-size: 32px;
						transition: opacity 0.3s ease-in-out;
						opacity: 0;

						&.fade_in {
							opacity: 1;
						}
						&.fade_out {
							opacity: 0;
						}
				  }

					.rotate_spin {
					  display: inline-block;
					  animation: spin 2s linear infinite;
					}

					@keyframes spin {
					  from {
					    transform: rotate(0deg);
					  }
					  to {
					    transform: rotate(360deg);
					  }
					}

				</style>

	      <div class='loading_spinner'>
	        <iconify-icon noobserver icon='${icons.loading}' class='loading rotate_spin'></iconify-icon>
	      </div>
	      `;

      // Create a MutationObserver to observe attribute changes
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === "attributes") {
            // this.syncAttribute(mutation.attributeName);
          }
        });
      });

      // Start observing the custom element for attribute changes
      observer.observe(this, { attributes: true });
    }

    connectedCallback() {
      setTimeout(() => {
        this.shadowRoot
          .querySelector("div.loading_spinner")
          .classList.add("fade_in");
      }, 0.01);
    }

    disconnectedCallback() {
      // console.log("LoadingSpinner removed from page.");
    }

    adoptedCallback() {
      // console.log("LoadingSpinner moved to new page.");
    }
  },
);
