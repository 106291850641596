function findParentWithAttribute(element, attributeName) {
  let currentElement = element;

  while (currentElement !== null) {
    if (currentElement.hasAttribute(attributeName)) {
      return currentElement;
    }

    currentElement = currentElement.parentElement;
  }

  return null;
}

export function parseDateYYYYMMDD(dateYYYYMMDD) {
  return new Date(
    dateYYYYMMDD.slice(0, 4), // Year
    dateYYYYMMDD.slice(4, 6) - 1, // Month (subtract 1)
    dateYYYYMMDD.slice(6, 8), // Day
  );
}

export const dateFormatLastPlayed = (lastPlayed) => {
  const now = new Date();
  const playedDate = new Date(lastPlayed);
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const diffDays = Math.floor((now - playedDate) / millisecondsPerDay);

  if (diffDays < 7) {
    return dateformat(lastPlayed, "DDDD"); // today/yesterday format
  } else {
    return dateformat(lastPlayed, "fullDate"); // 'Monday, October 21, 2024'
  }
};

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

window.findParentWithAttribute = findParentWithAttribute;
window.uuidv4 = uuidv4;

export function formatHHMMSSToSeconds(timestamp) {
  // Thanks, 4o.

  const parts = timestamp.split(":").map(Number); // Split by `:` and convert to numbers

  // If it's in the format `00:00` (minutes:seconds)
  if (parts.length === 2) {
    const [minutes, seconds] = parts;
    return minutes * 60 + seconds;
  }

  // If it's in the format `00:00:00` (hours:minutes:seconds)
  if (parts.length === 3) {
    const [hours, minutes, seconds] = parts;
    return hours * 3600 + minutes * 60 + seconds;
  }

  // In case the format doesn't match either expected pattern
  throw new Error("Invalid timestamp format");
}

export function formatSecondsToHHMM(seconds) {
  if (isNaN(seconds)) return "--:--";
  const timeMins = Math.max(Math.floor(seconds / 60), 0);
  const timeSecs = Math.max(Math.floor(seconds % 60), 0);
  const secString = timeMins.toString().padStart(2, "0");
  const minString = timeSecs.toString().padStart(2, "0");
  const formattedString = secString + ":" + minString;
  return formattedString;
}

export function formatDate(dateObj) {
  const today = new Date();
  const startOfWeek =
    today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1);
  const endOfWeek = startOfWeek + 6;
  const thisMonth = today.getMonth();
  const thisYear = today.getFullYear();

  const formatted = new Date(dateObj);
  const newWeek = formatted.getDate();
  const newMonth = formatted.getMonth();
  const newYear = formatted.getFullYear();

  let result;
  // This week
  if (newWeek >= startOfWeek && newWeek <= endOfWeek) {
    result = formatted.toLocaleString("en-GB", { weekday: "short" });
    // This Year
  } else if (newWeek < startOfWeek && thisYear == newYear) {
    result = formatted.toLocaleString("en-GB", {
      day: "numeric",
      month: "short",
    });
  } else {
    result = formatted.toLocaleString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }
  // console.log('formatDate ', formatted, thisYear, newYear);
  return result;
}

export function formatMessage(text) {
  // console.log('text: ', text);
  // console.log('text type', typeof text);

  if (typeof text === "string") {
    text = text.replace(/\n/g, "<br/>");
  }

  return text;
}

export function timeStampNow() {
  return new Date().toISOString();
}

export function formatDisplay(timestamp) {
  let date = new Date(timestamp);
  let options = {
    day: "numeric",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
  };
  return date.toLocaleString("en-GB", options);
}

// export function createElement({
//   element,
//   id,
//   classList,
//   innerHTML,
// })

export function partPill({ title, regex, cssClass }) {
  const lecturePart = title.match(regex);
  return lecturePart
    ? `${title.replace(lecturePart, "")} <span class='${cssClass}'>${lecturePart}</span>`
    : title;
}
