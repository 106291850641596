// User data class
import { getUserData, updateUserData } from "../restapi/restapi_update_user";

type profileData = {
  lastUpdated: string | undefined,
  nickname: string,
  birthday: string,
  gender: string,
  bio: string,
  psychology: {
    issueTargeted: {
      [key: string]: boolean,
    },
    attachmentStyle: {
      [key: string]: boolean,
    },
    dmmStyle: {
      [key: string]: boolean,
    },
    schemas: {
      [key: string]: boolean,
    },
  },
}

class Userdata {
  static localStorageUserData = [
    "AR_Userdata_Account",
    "AR_Userdata_wpData",
    "AR_Userdata_JWT",
    "AR_Userdata_PersonalFields",
    "AR_Userdata_journal_data",
    "AR_Userdata_Settings_theme",
    "AR_Userdata_Settings_meditation_libraryFilter_duration",
    "AR_Userdata_Settings_meditation_libraryFilter_purpose",
    "AR_Userdata_Settings_meditation_libraryFilter_issue_targeted",
    "AR_Userdata_Settings_meditation_libraryFilter_title",
    "AR_Userdata_Settings_meditation_libraryFilter_schema",
    "AR_Userdata_Settings_meditation_buttonView_current",
    "AR_Userdata_Settings_spc_tab_current",
    "AR_Userdata_Settings_spc_libraryFilter_issue_targeted",
    "AR_Userdata_Settings_courses_buttonView_current",
  ];

  static localStorageCustomData = [
    "AR_Userdata_profile_data",
    "AR_Userdata_meditation_data",
    "AR_Userdata_journal_data",
    "AR_Userdata_spc_data",
    "AR_Userdata_chat_data",
    "AR_Userdata_assessment_data",
  ];

  static UsageHandlers = {
    meditation_data: "meditationData",
    spc_data: "spcData",
    assessment_data: "assessmentData",
  };

  static ContentHandlers = {
    journal_data: "journalData",
    chat_data: "chatData",
  };

  static serializeMap(map) {
    if (map === null) {
      console.log("null, return blank Map...");
      return {
        __type: "Map",
        data: [],
      };
    }

    if (!(map instanceof Map)) {
      throw new Error("Input is not a Map");
    }

    const entriesArray = Array.from(map.entries());
    console.log("Map entries before serialization:", entriesArray); // Debug log

    return {
      __type: "Map",
      data: entriesArray,
    };
  }

  static deserializeMap(serializedMap) {
    // console.log('deserializeMap', typeof serializedMap);

    if (!serializedMap || serializedMap === "{}") {
      // console.log('none')
      return new Map();
    }

    if (typeof serializedMap !== "string") {
      throw new Error("Input is not a string");
    }

    // Parse the JSON string into an object
    let parsedObj;
    try {
      parsedObj = JSON.parse(serializedMap);
      // console.log('po', parsedObj);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null;
    }

    // Check if the parsed object is a serialized Map
    if (
      parsedObj &&
      parsedObj.__type === "Map" &&
      Array.isArray(parsedObj.data)
    ) {
      return new Map(parsedObj.data);
    }

    return null; // Return null or handle other types if needed
  }

  static saveToLS(keyName = null, data) {
    console.log('saveToLS...', data);

    /**
      Save one or multiple types
    **/
    if (keyName && data) {
      // console.log('saveToLS():', keyName, data);
      localStorage.setItem(`AR_Userdata_${keyName}`, JSON.stringify(data));
      return;
    }
  }

  static instance = null;

  static getInstance() {
    // Singleton pattern: ensure only ever one instance of Userdata
    if (!this.instance) {
      this.instance = new this();
    }

    return this.instance;
  }

  public profileData: profileData;

  constructor() {
    // The only userdata that exists regardless of whether they are logged in or not.
    this._wpData = {};
    this._jwt = null;
    this.isLoggedIn = false; // true|false
    this.appState = {};

    this.settings = {};
    this.customData = this.getCustomData();

    // User-generated Content
    // this.profileData = new UserContentHandler('profile_data');

    this.profileData = {
      lastUpdated: undefined,
      nickname: "",
      birthday: "",
      gender: "",
      bio: "",
      psychology: {
        issueTargeted: {
        },
        attachmentStyle: {
        },
        dmmStyle: {
        },
        schemas: {
        },
      },
    };

    this.journalData = new UserContentHandler("journal_data");
    this.chatData = new UserContentHandler("chat_data");

    // User's AR Content Stats
    this.spcData = new SPCUsageHandler("spc_data");
    this.meditationData = new MeditationHandler("meditation_data");
    this.assessmentData = new UserUsageHandler("assessment_data");

    // Listeners
    this.setupEventListeners();
  }

  setupEventListeners() {
    document.addEventListener("loginEvent", () => {
      console.log("🚪✅loginEvent...");
      this.setupUserInstance();
    });
    document.addEventListener("logoutEvent", () => {
      console.log("🚪❌logoutEvent...");
      this.clearUserInstance();
      this.clearLSData();
    });
  }

  setupUserInstance() {
    // get local data and server data to reconcile and save into the object.


    // Account Management
    this._wpData = this.getWpData();
    this._jwt = this.jwt;

    this.settings = {};
    this.customData = this.getCustomData();

    // Flags
    this.isLoggedIn = true;
    // console.log('👤 Userdata.setupUserInstance()', this);

    this.profileData = this.readLSData('profileData');
  }

  readLSData(keyName) {
    if (keyName) {
      return JSON.parse(localStorage.getItem(`AR_Userdata_${keyName}`));
    } else {
      Userdata.localStorageUserData.forEach(keyName => {
        console.log('readLSData', keyName);
      })
    }
  }

  clearUserInstance() {
    this.clearLSData();

    // Clear User-content
    this.journalData = null;
    this.chatData = null;

    this.spcData = null;
    this.meditationData = null;
    this.assessmentData = null;

    Userdata.instance = null;
    window.Userdata = Userdata.getInstance();
    console.log("👤 ❌ Userdata.clearUserInstance()");
  }

  // CustomData
  getCustomData(path) {
    if (path) {
      console.log(`userData.getCustomData(${path})`, path);
      return JSON.parse(localStorage.getItem(`AR_Userdata_CustomData_${path}`));
    } else {
      const customData = {};
      Userdata.localStorageCustomData.forEach((fieldName) => {
        const lsData = JSON.parse(
          localStorage.getItem(`AR_Userdata_CustomData_${fieldName}`),
        );
        customData[fieldName] = lsData;
      });
      return customData;
    }
  }

  setCustomData(path, data) {
    const customData = {
      meditationUsage: {
        id: "meditationDataObj",
      },
      journalData: {
        uuid: "journalEntryObj",
      },
      profileData: {
        personal: {
          nickname: null,
          birthday: null,
          gender: null,
          personalBio: null,
        },
      },
      assessmentData: {
        id: "resultObj", // contains user responses and calculated result
      },
      chatData: [
        {},
        {}, // array of chatInstance objects
      ],
      settingsData: {
        appearanceMode: "light",
        notifications: {
          newContent: {
            spc: false,
            meditation: false,
            onlineEvent: false,
          },
          newFeatures: false,
          liveEvents: {
            all: false,
            onlineEvent: [], // list of events they have registered for.
          },
        },
      },
      appState: {
        libraryFilterSettings: {},
        route: {},
      },
    };

    /**

      Functions to define and reconile each of these data,
      between the localCopy and serverCopy.

    **/

    if (path) {
      console.log("set", path);
      this.customData[path] = data;
      console.log("setted", this.customData[path]);
      localStorage.setItem(
        `AR_Userdata_CustomData_${path}`,
        JSON.stringify(data),
      );
    }

    return customData;
  }

  getWpData(key) {
    if (key) {
      return (
        this._wpData[key] ??
        JSON.parse(localStorage.getItem("AR_Userdata_wpData"))?.[key]
      );
    }
    return (
      this._wpData ?? JSON.parse(localStorage.getItem("AR_Userdata_wpData"))
    );
  }

  setWpData(wpAccountFields) {
    this._wpData = wpAccountFields;
    localStorage.setItem("AR_Userdata_wpData", JSON.stringify(this._wpData));
  }

  /**

    Methods to Access Data

  **/
  get membershipLevel() {
    return this._wpData.membership_level;
  }

  profile(key) {
    return this.customData.profileData.personal[key];
  }

  /**

    JWT

  **/
  get jwt() {
    return this._jwt ?? localStorage.getItem("AR_Userdata_JWT");
  }

  set jwt(token = null) {
    this._jwt = token;
    localStorage.setItem("AR_Userdata_JWT", token);

    if (!token) {
      localStorage.removeItem("AR_Userdata_JWT");
    }
  }

  /**
    Settings
  **/
  getSettings(path) {
    // console.log('getSettings() path:', `AR_Userdata_Settings_${path}`)
    return (
      this.settings[path] ??
      JSON.parse(localStorage.getItem(`AR_Userdata_Settings_${path}`))
    );
  }

  setSettings(path, data) {
    this.settings[path] = data;
    localStorage.setItem(`AR_Userdata_Settings_${path}`, JSON.stringify(data));

    if (!data) {
      localStorage.removeItem(`AR_Userdata_Settings_${path}`);
    }
  }

  /**

    General Methods

  **/

  clearLSData(key = null) {
    if (key) {
      localStorage.removeItem(key);
    } else {
      // Remove all keys
      Userdata.localStorageUserData.forEach((fieldName) => {
        localStorage.removeItem(fieldName);
      });
      Userdata.localStorageCustomData.forEach((fieldName) => {
        localStorage.removeItem(fieldName);
      });

      // todo: Leaving this here until we setup proper way to handle profileData
      localStorage.removeItem("AR_Userdata_CustomData_profileData");
    }
  }

  async saveToServer(keyName) {
    /**

      This function would be fine to use with a service-worker
      or when the user leaves the app.

      [] Need to implement some kind of debouncing or rate limiting. Can a simple time counter function to limit to at least 10 seconds or something.
      And we'd need to assemble the types of requests and sum them up.

    **/

    await this.reconcileUserData(keyName).then((mergedData) => {
      console.log("saveToServer() ready to send...", keyName, mergedData);

      /**
        UsageHandlers
      **/
      if (Userdata.UsageHandlers[keyName]) {
        mergedData = Userdata.serializeMap(mergedData);
      }

      switch (keyName) {
        /**
          ContentHandlers
        **/
        case "journal_data":
          break;
        case "profile_data":
          break;
        case "chat_data":
          break;
        default:
          break;
      }

      console.log("saveToServer() presend:", mergedData);

      if (mergedData) {
        updateUserData({ [keyName]: mergedData });
        // Userdata.saveToLS(keyName, mergedData);
      }
    });
  }

  keyToData(keyName) {
    return;
  }

  async reconcileUserData(keyName) {
    console.log("reconcileUserData() keyName:", keyName);

    /**
      localData needs to know if its serialized Map or an Array.... based on key name.
    **/

    // let localData = JSON.parse(localStorage.getItem(`AR_Userdata_${keyName}`)) ?? null;

    let localData;
    switch (keyName) {
      /**
        Usage Handlers...
      **/
      case "meditation_data":
        localData = this.meditationData.usageData;
        break;
      case "spc_data":
        localData = this.spcData.usageData;
        break;
      case "assessment_data":
        localData = this.assessmentData.usageData;
        break;

      /**
        Content Handlers...
      **/
      case "journal_data":
        localData = this.journalData.data;
        break;
      case "profile_data":
        localData = this.profileData.data;
        break;
      case "chat_data":
        localData = this.chatData.data;
        break;
      default:
        throw new Error("reconcileUserData(): mergedData wrong keyName...");
    }

    // console.log('reconcileUserData() localData', localData);

    return getUserData([keyName]).then((response) => {
      const serverData = response.data;

      // console.log('getUserData() response:', response);
      // console.log('localData', localData);
      // console.log('serverData', keyName, serverData[keyName]);

      if (serverData[keyName] && response.status === 200) {
        // console.log('return merged data...');
        let mergedData = this.mergeLocalAndServerData(
          localData,
          serverData,
          keyName,
        );

        /**
            Here mergedData is sometimes:
            - empty string '{}'
            - null
            - Map
          **/

        // console.log('time to merge', mergedData);

        if (Userdata.UsageHandlers[keyName]) {
          if (mergedData === null || mergedData === "{}") {
            mergedData = Userdata.deserializeMap(mergedData);
          }

          // console.log('merged proper:', mergedData)

          this[Userdata.UsageHandlers[keyName]].usageData = mergedData;

          const serialized = Userdata.serializeMap(mergedData);
          // console.log('serialized', serialized);

          Userdata.saveToLS(keyName, serialized);
          return mergedData;
        } else {
          // Serialize before storing
          switch (keyName) {
            /**
              ContentHandlers
            **/
            case "journal_data":
              this.journalData.data = mergedData;
              break;
            case "profile_data":
              this.profileData.data = mergedData;
              break;
            case "chat_data":
              this.chatData.data = mergedData;
              break;
            default:
              break;
          }

          // console.log('serialized and merged:', mergedData);

          // Save as the new canonical local source
          Userdata.saveToLS(keyName, mergedData);
          // localStorage.setItem(`AR_Userdata_${keyName}`, mergedData);

          return mergedData;
        }
      } else {
        // console.log('return local data...');
        return localData;
      }
    });
  }

  mergeLocalAndServerData(localCopy, serverCopy, keyName) {
    // merge then compare timestamps for dupes, keep newest

    // console.log('mergeLocalAndServerData()')
    // console.log('localCopy', localCopy);
    // console.log('serverCopy', serverCopy[keyName]);

    // Start with localCopy
    if (keyName === 'profileData') {
      //
    } else if (Array.isArray(localCopy)) {
      // console.log('USER-CONTENT')
      return this.mergeUserContentHandler(localCopy, serverCopy, keyName);


    } else if (localCopy instanceof Map) {
      // console.log('USER-USAGE')
      return this.mergeUserUsageHandler(
        localCopy,
        serverCopy[keyName],
        keyName,
      );
    }
  }

  mergeUserContentHandler(localCopy, serverCopy, keyName) {
    const merged = [...localCopy];
    if (serverCopy.hasOwnProperty([keyName])) {
      // console.log("mergeUserContentHandler()", serverCopy[keyName]);

      /**
        It might not need parsing... how to check. If its a string.
      */
      let deserializedServerCopy = serverCopy[keyName];
      if (typeof deserializedServerCopy === "string") {
        deserializedServerCopy = JSON.parse(serverCopy[keyName]);
        console.log(
          "mergeUserContentHandler() deserialized",
          deserializedServerCopy,
        );
      }

      deserializedServerCopy.forEach((serverItem) => {
        const uuid = merged.findIndex(
          (localItem) => localItem.uuid === serverItem.uuid,
        );

        if (uuid < 0) {
          merged.push(serverItem);
        } else {
          // Keep it if its a record of a deleted uuid
          if (serverItem.deleted === true) {
            merged[uuid] = serverItem;
          }

          // Keep whichever is newer
          if (new Date(serverItem.date) > new Date(merged[uuid].date)) {
            merged[uuid] = serverItem;
          }
        }
      });

      console.log("mergeUserContentHandler() merged", merged);

      // const filtered = Object.values(merged).reduce((acc, obj) => {
      //   const current = acc[obj.uuid];
      //   if (!current || obj.date > current.date) {
      //     return acc;
      //   }
      // }, {});
      // console.log('filtered', filtered);
    }

    return merged;
  }

  mergeUserUsageHandler(localCopy, serverCopy, keyName) {
    console.log("mergeUserUsageHandler()");
    // console.log('--localCopy', localCopy);
    // console.log('--serverCopy', serverCopy);

    /**
      localCopy: Should always be Map now

      serverCopy: ?

      the serverCopy will be a serializeMap. So here is where we deserialize it into our Map.

      [] then we need to update this function to make sure it works with maps as inputs

      since the serialize methods are instance methods... we can't access them here unless we initialise a new handler. or, i guess we can call the

    **/

    if (serverCopy.__type === "Map") {
      serverCopy = new Map(serverCopy.data);
    } else {
      serverCopy = Userdata.deserializeMap(serverCopy);
    }

    // console.log('--deserialized serverCopy', serverCopy);
    const mergedCopy = new Map(serverCopy);
    // console.log('--mergedCopy', mergedCopy);

    localCopy.forEach((localItem, meditationId) => {
      const serverItem = mergedCopy.get(meditationId);

      if (
        !serverItem ||
        new Date(localItem.userStats.timestamp) >
        new Date(serverItem.userStats.timestamp)
      ) {
        mergedCopy.set(Number(meditationId), localItem);
      }
    });

    return mergedCopy;

    const itemMap = new Map();

    // Access the array within localCopy and serverCopy using the keyName
    [...Object.values(localCopy), ...Object.values(serverCopy)].forEach(
      (item) => {
        // Determine the unique ID based on the keyName
        const id =
          keyName === "meditation_data" ? item.meditationId : item.uuid;

        const existingItem = itemMap.get(id);

        // Compare the timestamp or replace if not found in the map
        if (
          !existingItem ||
          (!item.deleted &&
            new Date(item.userStats.timestamp) >
            new Date(existingItem.userStats.timestamp))
        ) {
          // Only set the item if it isn't marked as 'deleted'
          if (!item.deleted) {
            itemMap.set(Number(id), item);
          }
        }
      },
    );

    /**
      Here we should serialise the map for usage in LS / Server
    **/
    const serializeMap = Userdata.serializeMap(itemMap);
    return serializeMap;
  }
}

export default Userdata;

class UserContentHandler {
  constructor(keyName) {
    this.keyName = keyName;
    this.data = []; // Array of user-generated content objects

    this.readDataFromStorage(keyName);
  }

  readDataFromStorage(keyName) {
    this.data =
      JSON.parse(localStorage.getItem(`AR_Userdata_${keyName}`)) ?? [];
  }

  save(obj) {
    this.data.unshift(obj);
  }

  delete(obj) {
    if (obj) {
      obj.deleted = true;
      this.data.push(obj);

      // Save this operation
      Userdata.saveToLS(this.keyName, this.data);
      // Userdata.saveToServer(this.keyName);
    }
  }

  set update(obj) {
    //
  }

  find({ date, meditationId, uuid }) {
    /**
      Allow any parameters to be matched in and all are checked for
    **/
    // console.log('date', dateformat(date, 'dateYYYYMMSS'))

    if (uuid) {
      // return this.usageData[uuid];
    }

    return this.data?.find((data) => {
      // console.log('data.date', dateformat(data.date, 'dateYYYYMMSS'));

      return (
        data.meditationId === meditationId &&
        dateformat(data.date, "dateYYYYMMSS") ===
        dateformat(date, "dateYYYYMMSS") &&
        data.deleted !== true
      );
    });
  }

  getAllByKey(keyName, keyVal) {
    /**
      Go through this.usageData
      for each key, check if the
    **/

    if (this.data) {
      const results = Object.values(this.data).reduce((acc, entry) => {
        if (
          Number(entry[keyName]) === Number(keyVal) &&
          entry.deleted !== true
        ) {
          acc.push(entry);
        }
        return acc;
      }, []);

      // console.log('getAllByKey', keyName, keyVal);
      // console.log('getAllByKey results', results);
      return results;
    } else {
      return [];
    }
  }
}

class UserUsageHandler {
  /*
    handles just one of the following:
    - meditation,
    - spc,
    - online_event
    - assessment
  */

  constructor(keyName) {
    this.keyName = keyName;
  }
}

class MeditationHandler extends UserUsageHandler {
  /**
    this handler is accessed on the Userdata singleton, as Userdata.meditationData.

    Any information about the meditation stats for a user are contained on this object.
    It has a Map (named usageData) which contains each meditation that the user has done. These are instances of the Meditation class.

  */

  constructor(keyName) {
    super(keyName);
    this.keyName = keyName;
    this.usageData = localStorage.getItem(`AR_Userdata_${keyName}`)
      ? Userdata.deserializeMap(localStorage.getItem(`AR_Userdata_${keyName}`))
      : new Map();

    console.log(`new Handler: ${keyName}`, this.usageData);
  }

  update(usageStatsObj) {
    console.log(`${this.keyName} -------UPDATE-----`);
    console.log(this.usageData);

    this.usageData.set(Number(usageStatsObj.meditationId), usageStatsObj);

    Userdata.saveToLS(this.keyName, Userdata.serializeMap(this.usageData));

    // localStorage.setItem(`AR_Userdata_${this.keyName}`, JSON.stringify(Userdata.serializeMap(this.usageData)));

    // console.log('meditationData.usageData.size:', this.usageData.size);
    // console.log('usageStatsObj', usageStatsObj)
    // console.log('this.usageData', this.usageData)
    // console.log('this.usageData serialized', serializeMap(this.usageData));

    window.Userdata.saveToServer(this.keyName);
  }

  sortByLastPlayed() {
    /**
      Sorts the usageData by when it was last played, latest comes first
    **/
    let nanFound = false;
    const recentHistoryLimit = 50;
    const list = Array.from(this.usageData)
      .sort(
        (a, b) => {
          const dateA = new Date(b[1].userStats.lastPlayed).getTime();
          const dateB = new Date(a[1].userStats.lastPlayed).getTime();
          if (isNaN(dateA) || isNaN(dateB)) nanFound = true;

          return dateB - dateA;
        }
      )
      .slice(0, recentHistoryLimit);
    console.error('dateA or dateB is NaN');
    return list;
  }

  getFavourites() {
    /**
      Return the items in this.usageData that have the favourites property set true
    */
    const favMeditations = [];
    this.usageData.forEach((meditation) => {
      if (meditation.userStats.favourite) {
        favMeditations.push(meditation);
      }
    });
    return favMeditations;
  }
}

class SPCUsageHandler extends UserUsageHandler {
  /**
    this handler is accessed on the Userdata singleton, as Userdata.meditationData.

    Any information about the meditation stats for a user are contained on this object.
    It has a Map (named usageData) which contains each meditation that the user has done. These are instances of the Meditation class.

  */

  constructor(keyName) {
    super(keyName);
    this.keyName = keyName;
    this.usageData = localStorage.getItem(`AR_Userdata_${keyName}`)
      ? Userdata.deserializeMap(localStorage.getItem(`AR_Userdata_${keyName}`))
      : new Map();

    console.log(`new Handler: ${keyName}`, this.usageData);
  }

  update(usageStatsObj) {
    console.log(`${this.keyName} -------UPDATE-----`);

    this.usageData.set(Number(usageStatsObj.postId), usageStatsObj);
    console.log(this.usageData);

    Userdata.saveToLS(this.keyName, Userdata.serializeMap(this.usageData));

    // console.log('spcData.usageData.size:', this.usageData.size);
    // console.log('usageStatsObj', usageStatsObj)
    // console.log('this.usageData', this.usageData)
    // console.log('this.usageData serialized', serializeMap(this.usageData));

    window.Userdata.saveToServer(this.keyName);
  }

  getListOfStartedSPCs() {
    return new Map(
      [...this.usageData].filter(spc => spc[1].userStats.started)
    );
  }

  getFavourites() {
    /**
      Return the items in this.usageData that have the favourites property set true
    */
    const favSPCs = [];
    this.usageData.forEach((spc) => {
      if (spc.userStats.favourite) {
        favSPCs.push(spc);
      }
    });
    return favSPCs;
  }
}
