import * as ar from "../data/ar_globals.js";
import { authHeader } from "../restapi/restapi_login_user.js";
import { apiURL } from "../restapi/restapi_requests.js";

// // Add event listener for before page unload
// window.addEventListener('beforeunload', periodicServerUpdate);

// // Set up periodic sync every N minutes
// setInterval(periodicServerUpdate, N * 60 * 1000);

// Sync to Server
export function syncChatsToServer(userID) {
  // console.log('syncChatsToServer() userID', userID);
  const id = window.Userdata._wpData.id;

  // console.log("🛜 syncChatsToServer(): run");
  const dbRequest = indexedDB.open(ar.AppDB, ar.DB_VER);
  dbRequest.onsuccess = function (event) {
    const db = event.target.result;
    const transaction = db.transaction("chatInstanceData", "readonly");
    const store = transaction.objectStore("chatInstanceData");
    const getAllRequest = store.getAll();

    getAllRequest.onsuccess = function () {
      const chatsArray = getAllRequest.result;
      const chatsJSON = JSON.stringify(chatsArray);

      fetch(apiURL("ar", "update-user-chats"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader(),
        },
        body: JSON.stringify({
          userID: id,
          userChats: chatsJSON,
        }),
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((data) => {
          // handle successful server response
          // console.log(
          //   "syncChatsToServer(): Chats synced to server successfully: ",
          //   data,
          //   chatsArray.length,
          // );
        })
        .catch((error) => {
          // handle errors
          console.error(
            "syncChatsToServer(): Failed to sync chats to server: ",
            error,
          );
        });
    };
  };
}

// Get Chat data from the server
export async function fetchServerChatData() {
  try {
    const response = await fetch(apiURL("ar", "get-chat-data"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    });
    if (response.ok) {
      const data = await response.json();
      let formattedData = "";
      try {
        if (data && data[0]) {
          formattedData = JSON.parse(data[0]);
        }
      } catch (error) {
        console.error(
          "fetchServerChatData() Invalid JSON. Returning empty array instead.",
          error,
        );
        return [];
      }

      // console.info("🛜 💬 fetchServerChatData(): ", formattedData);
      return formattedData;
    } else {
      console.error("Server responded with non-OK status", response.status);
      return []; // Return empty array in case of failure
    }
  } catch (error) {
    console.error("Failed to fetch chat data from server", error);
    return []; // Return empty array in case of error
  }
}

// Get Data from IDB
export function loadIDBChatData() {
  return new Promise((resolve, reject) => {
    const dbRequest = indexedDB.open(ar.AppDB, ar.DB_VER);

    dbRequest.onerror = function (event) {
      reject("IndexedDB error: " + event.target.errorCode);
    };

    dbRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("chatInstanceData", "readonly");
      const store = transaction.objectStore("chatInstanceData");
      const getAllRequest = store.getAll();

      getAllRequest.onerror = function (event) {
        reject("Could not retrieve data from IndexedDB:", event.target.error);
      };

      getAllRequest.onsuccess = function (event) {
        resolve(event.target.result); // This will be an array of chat records
      };
    };

    // No need for onupgradeneeded here since we're only reading data
  });
}

// Reconcile Server vs IDB Chat Data
export function reconcileChatData(serverData, idbData) {
  // Convert serverData to a map for faster access
  let serverDataMap = new Map(serverData.map((item) => [item.chatID, item]));

  // Filtering IDB data: Remove chats not present on the server unless flagged as new
  let filteredIDBData = idbData.filter((idbItem) => {
    return serverDataMap.has(idbItem.chatID) || idbItem.isNew;
  });

  // Merge data: Use server data as base, override with newer IDB data where applicable
  let reconciledData = serverData.map((serverItem) => {
    let idbItem = serverDataMap.get(serverItem.chatID);
    if (
      idbItem &&
      idbItem.chatModifiedTimestamp > serverItem.chatModifiedTimestamp
    ) {
      return idbItem; // Local item is newer
    }
    return serverItem; // Server item is newer or same
  });

  // Include new chats that are in IDB but not yet on the server
  filteredIDBData.forEach((idbItem) => {
    if (!serverDataMap.has(idbItem.chatID) && idbItem.isNew) {
      reconciledData.push(idbItem);
    }
  });

  updateIndexedDBWithReconciledData(reconciledData);

  return reconciledData;
}

export async function updateIndexedDBWithReconciledData(reconciledData) {
  const dbRequest = indexedDB.open(ar.AppDB, ar.DB_VER);

  dbRequest.onsuccess = function (event) {
    const db = event.target.result;
    const transaction = db.transaction("chatInstanceData", "readwrite");
    const store = transaction.objectStore("chatInstanceData");

    // Clear all existing entries
    store.clear().onsuccess = () => {
      // Add or update new reconciled entries
      reconciledData.forEach((chatItem) => {
        store.put(chatItem);
      });
    };

    transaction.oncomplete = function () {
      // console.log(
      //   "💬 IndexedDB updated with reconciled chat data successfully!",
      // );
    };

    transaction.onerror = function (event) {
      console.error(
        "Error updating IndexedDB with reconciled data:",
        event.target.error,
      );
    };
  };

  dbRequest.onerror = function (event) {
    console.error(
      "Error opening database for updating: ",
      event.target.errorCode,
    );
  };
}
