import { Meditation } from "../classes/Meditation.js";
import { SPC } from "../classes/SPC.js";
import {
  meditationTaxonomies,
  spcTaxonomies,
  enabledMeditationTaxonomies,
  enabledSPCTaxonomies,
} from "../data/ar_globals.js";
import { termSlugToName } from "../data/ar_taxonomies.js";
import { printTaxonomyPill } from "../taxonomies/printTaxonomyPill.js";

export function printTaxonomyPillsContainer({ postInstance }) {
  if (
    !postInstance.taxonomyData ||
    Object.values(postInstance.taxonomyData).length < 1
  )
    return;

  const taxonomySet = (() => {
    switch (true) {
      case postInstance instanceof Meditation:
        return {
          enabled: enabledMeditationTaxonomies,
          info: meditationTaxonomies,
        };
      case postInstance instanceof SPC:
        return {
          enabled: enabledSPCTaxonomies,
          info: spcTaxonomies,
        };
      default:
        return null;
    }
  })();
  // console.log('taxonomyPill set', taxonomySet);

  if (!taxonomySet) return;

  // Print taxonomy labels into this pillsContainer
  const pillsContainer = document.createElement("div");
  pillsContainer.classList.add("pills_container");

  taxonomySet.enabled.forEach((taxonomySlug: string) => {
    const taxonomyValues =
      JSON.parse(postInstance.taxonomyData[taxonomySlug]) ?? [];

    if (taxonomyValues) {
      const termBubbles = taxonomyValues
        .map((termSlug: string) => printTaxonomyPill(termSlug, taxonomySlug).outerHTML)
        .join("");

      if (termBubbles.length) {
        const taxonomyContainer = document.createElement("div");
        taxonomyContainer.classList.add("taxonomy_container");
        taxonomyContainer.innerHTML = `
            <div class='label'>${taxonomySet.info[taxonomySlug].name_short}</div>
            <div class='terms_container'>${termBubbles}</div>
          `;
        pillsContainer.appendChild(taxonomyContainer);
      }
    }
  });

  if (pillsContainer.children.length > 0) return pillsContainer;
}
