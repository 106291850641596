import { appState } from "../data/ar_globals.js";
import { createMeditationRow } from "../meditation/meditation_row.js";
import { icons } from "../ui_general/icons.ts";
import { createDatePill } from "../ui_components/date_pill.ts";
import { dateFormatLastPlayed } from "../ui_general/ar_utils.ts";
import {
  filterButton,
  getLibraryFilterSettings,
  libraryFilter,
} from "../ui_components/library_filter.js";
import {
  processMeditationPosts,
  uiGetMeditationPosts,
} from "../meditation/meditationFunctions.js";
import { MeditationController } from "../meditation/meditation_controller.ts";

export const sectionMeditation = document.createElement("section");
sectionMeditation.setAttribute("id", "meditation");
sectionMeditation.classList.add("membership", "hidden");
sectionMeditation.innerHTML = `
  <div class="container">

    <div class="section_header">
      <div class='header_wrap'>
        <iconify-icon noobserver icon="${icons.postType.meditation}"></iconify-icon>
        <div class='label'>Meditation Library</div>
      </div>
    </div>

    <div class="contents">

      <div class="button_view_set">
        <div class='button_view_wrap current' data-name='library_main'>
          <div class="button_view">
            <iconify-icon noobserver icon='${icons.sortingLeft}'></iconify-icon>
            <div class="label">Library</div>
          </div>
        </div>
        <div class='button_view_wrap' data-name='library_recent'>
          <div class="button_view">
            <iconify-icon noobserver icon="${icons.clock}"></iconify-icon>
            <div class="label">History</div>
          </div>
        </div>
        <div class='button_view_wrap' data-name='library_favs'>
          <div class="button_view">
            <iconify-icon noobserver icon="${icons.fav.off}"></iconify-icon>
            <div class="label">Favourites</div>
          </div>
        </div>
      </div>

      <div class="block view_set current" id="library_main">

        <div id='meditation_list' class='meditation_list'>
        </div>

        <div id='meditation_list_prev_next_wrap' class='pagination_wrap'>
          <button-arrow direction="left" section="meditation"></button-arrow>
          <div class="pageCount">
            <div class="count_widget" id='currentPage'></div>/
            <div class="count_widget" id='totalPages'></div>
          </div>
          <button-arrow direction="right" section="meditation"></button-arrow>
        </div>
      </div>

      <div class="block view_set" id="library_recent">
        <div class="subheader">Recently Listened</div>
        <div class='meditation_list' id='library_recent_list'></div>
      </div>
      <div class="block view_set" id="library_favs">
        <div class="subheader">Favourites</div>
        <div class='meditation_list' id="library_favs_list"></div>
      </div>

    </div>
  </div>`;
sectionMeditation.querySelector("div#library_main").append(libraryFilter);
sectionMeditation
  .querySelector(`div[data-name=library_main]`)
  .append(filterButton);

document.addEventListener("meditationFavUpdateEvent", () => {
  updateFavsListDOM();
});

document.addEventListener("meditation_update_playcount", () => {
  console.log('meditation_update_playcount()')
  uiUpdateListenedMeditationsListDOM()
});

document.addEventListener("meditation_load_event", async (event) => {
  console.log("meditation_load_event");
  if (appState.meditationLibraryState.isLoading) return;

  console.log("meditation_load_event:", event);
  console.log("meditationlib", appState.meditationLibraryState);
  const { direction } = event.detail;

  appState.meditationLibraryState.isLoading = true;

  if (direction === "left" && appState.meditationLibraryState.page > 1) {
    appState.meditationLibraryState.page -= 1;
  } else if (direction === "right") {
    appState.meditationLibraryState.page += 1;
  }

  try {
    // Invoke the network request and wait for it to complete
    await uiGetMeditationPosts({
      page: appState.meditationLibraryState.page,
      postsPerPage: 20,
      ...getLibraryFilterSettings("meditation"),
    });
  } catch (error) {
    console.error("Failed to fetch Meditation posts:", error);
  } finally {
    console.log("dispatch", appState.meditationLibraryState);
    const loadCompleteEvent = new CustomEvent("restAPILoadComplete", {
      bubbles: true,
      composed: true,
      detail: {
        page: appState.meditationLibraryState.page,
        totalPages: appState.meditationLibraryState.totalPages,
        section: "meditation",
      },
    });
    document.dispatchEvent(loadCompleteEvent);
    appState.meditationLibraryState.isLoading = false;

    // Scroll to top
    sectionMeditation.scrollTo(0, 0);
  }
});

// Functions
export async function meditationLibraryInit() {
  // console.log('meditationLibraryInit', 'token', appState.loginToken, 'first init')

  // Check if we've already synced from server at app-launch to avoid doing it again
  if (appState.loginToken && !appState.meditationLibraryInit) {
    await window.Userdata.reconcileUserData("meditation_data").then(() => {
      appState.meditationLibraryInit = true;

      uiGetMeditationPosts();
      uiUpdateListenedMeditationsListDOM();
      updateFavsListDOM();

    });
  } else {
    uiGetMeditationPosts();
  }
}

function updateFavsListDOM() {
  // return;
  const favsListDOM = sectionMeditation.querySelector("div#library_favs_list");
  const favs = window.Userdata.meditationData.getFavourites();
  processMeditationPosts({ posts: favs, target: favsListDOM });
}

function uiUpdateListenedMeditationsListDOM() {
  // return;
  const reverseMeditationHistory =
    window.Userdata.meditationData.sortByLastPlayed();

  if (reverseMeditationHistory.length) {
    const libraryRecentList = document.querySelector("div#library_recent_list");
    libraryRecentList.innerHTML = "";

    const printedDates = [];
    reverseMeditationHistory.reverse().forEach(([_, meditationSummaryData]) => {
      const dateString = dateFormatLastPlayed(
        meditationSummaryData.userStats.lastPlayed,
      );

      if (!printedDates.includes(dateString)) {
        printedDates.push(dateString);

        const datePill = createDatePill(dateString);
        libraryRecentList.appendChild(datePill);
      }

      const meditationRow = createMeditationRow({
        meditationSummaryData: meditationSummaryData,
      });
      libraryRecentList.appendChild(meditationRow);
    });
  }
}

document.addEventListener("loginEvent", function () {
  uiGetMeditationPosts();
  updateFavsListDOM();
  uiUpdateListenedMeditationsListDOM();
});

document.addEventListener("logoutEvent", () => {

  // Clear Favs
  const subsectionFavourites = (document.querySelector(
    "#library_favs div.meditation_list",
  ).innerHTML = "");

  // Clear Recents
  const subsectionRecent = (document.querySelector(
    "#library_recent div.meditation_list",
  ).innerHTML = "");

  uiGetMeditationPosts();

});
