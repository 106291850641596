import "iconify-icon";
import { icons } from "../ui_general/icons.ts";

customElements.define(
  "completion-bar",
  class CompletionBar extends HTMLElement {

    constructor() {
      super();

      this.attachShadow({ mode: "open" });
      this.shadowRoot.innerHTML = `
        <style>
          :host {
          }

          :host([completion-percentage='100']) {
            div.completion_bar {
              div.bar_wrap {
                div.bar_element {
                  --complete-color: rgb(106, 214, 202);
                  background-color: var(--complete-color);
                  border-right-color: var(--complete-color);
                }
              }

              iconify-icon.completion {
                color: hsla(217, 100%, 78%, 0.47);
                font-size: 26px;
                // visibility: visible;
                // width: auto;
              }
            }
          }

          div.completion_bar {
            --height: 30px;
            --width: 80px;
            --bg-colour: hsla(217, 100%, 78%, 0.75);

            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            column-gap: 8px;

            color: var(--c-lavendar4);

            div.bar_wrap {
              width: 100%;
              display: block;
              background: hsla(217, 100%, 78%, 0.27);
              height: 10px;
              border-radius: 4px;
              overflow: hidden;
              transition: width 0.2s ease-in-out;

              div.bar_element {
                border-right: 2px solid #edb955;
                background-color: #ffcf9c;
                height: 100%;
                width: 0%;
                transition:
                  width 0.2s ease-in-out,
                  background-color 0.2s ease-in-out;
              }
            }

            div.count {
              padding: 0 10px;
              font-size: 0.9em;
              opacity: 0.7;
              text-wrap: nowrap;
            }

            iconify-icon.completion {
              transition: all 0.2s ease-in-out;
              width: 0;
              height: 26px;
              visibility: hidden;

              display: none;
            }
          }
        </style>

        <div class='completion_bar off'>
          <div class='bar_wrap'>
           <div class='bar_element'></div>
          </div>
          <iconify-icon noobserver icon='${icons.check.off}' class='completion'></iconify-icon>
          <div class='count'></div>
        </div>
        `;
    }

    static get observedAttributes() {
      return ["completion-percentage"];
    }

    attributeChangedCallback(attribute, oldValue, newValue) {
      if (attribute === "completion-percentage" && oldValue !== newValue) {
        this.shadowRoot.querySelector("div.bar_element").style.width = `${newValue}%`;
      }
    }

    connectedCallback() {
      setTimeout(() => {
        this.shadowRoot
          .querySelector("div.completion_bar")
          .classList.add("fade_in");
      }, 0.01);
    }

    disconnectedCallback() {
      // console.log("LoadingSpinner removed from page.");
    }

    adoptedCallback() {
      // console.log("LoadingSpinner moved to new page.");
    }
  },
);
