import { uuidv4 } from "../ui_general/ar_utils.js";
import { displayJournalEntries } from "../journal/journal_entries.js";
import { MeditationSummaryData } from "./Meditation";

export class JournalEntry {

  public readonly uuid: string;
  public readonly date: Date;
  public readonly meditationId: number;
  public readonly meditationData: MeditationSummaryData;
  public text: string;

  constructor({
    text,
    meditationId,
    uuid = uuidv4(),
    date = new Date(),
    meditationData,
  }: {
    text: string
    meditationId: number,
    uuid: string,
    date: Date,
    meditationData: MeditationSummaryData
  }) {
    this.uuid = uuid;
    this.date = date;
    this.text = text;
    this.meditationId = meditationId;
    this.meditationData = Object.assign({}, meditationData);
    delete this.meditationData.transcript;
  }

  save(text: string) {
    this.text = text;
    this.storeEntry();
  }

  storeEntry() {
    const localStorageJournalEntries = localStorage.getItem("AR_Userdata_journal_data");

    const journalEntries = localStorageJournalEntries
      ? JSON.parse(localStorageJournalEntries)
      : [this];

    // Update an existing entry?
    const findExistingEntry = journalEntries.find((entry: JournalEntry) => {
      const existingEntry =
        entry.date === this.date && entry.meditationId === this.meditationId;

      if (existingEntry) {
        entry.text = this.text;
        return true;
      }
    });

    // Push a new entry?
    if (!findExistingEntry) {
      journalEntries.push(this);
    }

    // Filter empty entries
    const filteredJournalEntries = journalEntries.filter((entry: JournalEntry) => entry.text);

    console.log("storeEntry", filteredJournalEntries);

    // Save the data...
    // ...to Local Storage
    localStorage.setItem(
      "AR_Userdata_journal_data",
      JSON.stringify(filteredJournalEntries),
    );

    // ...to Userdata
    window.Userdata.journalData.save(this);

    // ...to server
    window.Userdata.saveToServer("journal_data");

    displayJournalEntries({ journalEntries: filteredJournalEntries, omit: this.uuid });
  }

  delete() {
    window.Userdata.journalData.delete(this);
  }

  update(text: string) {
    this.text = text;
    window.Userdata.journalData.update(this);
  }

  getRelatedEntries(meditationId: number) {
    /**
      Returns all other journal entries associated with this meditationId;
    **/
    return window.Userdata.journalData.getAllByKey(meditationId);
    // return window.Userdata.meditationData(meditationId).journalEntries();
  }
}
