import { MeditationPlayer } from "../meditation/meditationPlayer.js";
import { SPC } from "./SPC";
import { icons } from "../ui_general/icons.js";

export type SourceSpc = {
  spc_id: number;
  spc_name: string;
};

export type MeditationSummaryData = {
  /**
    This data corresponds to the Wordpress custom type data or metadata that is set on each 'meditation' CPT in the AR Meditation library. This type contains a list of all the data keys that are returned by Wordpress via the REST API for each meditation.
  */

  /**
    Each meditation has a unique ID integer. It refers to a unique meditation from the AR meditation library. This is set in Wordpress on the meditation's custom post type.
  */
  meditationId: number;

  /**
    url to the B2 bucket containing the meditation audio file.
    Is null if the canAccess property is false.
    eg.  "https://f000.backblazeb2.com/file/ARPublic/Meditation/Workshops/2024_07-Balance_Responsibility/%5B2024-07%5DBalance_Responsibility-GM-Compassion.mp3"
  */
  audioUrl: string | null;

  /**
    Whether the server has determined that the user has access to this file. We use this to update the UI if the meditation is of a higher membership-tier than the user is paying. If false, the audioUrl property will be null.
  */
  canAccess: boolean;

  /**
    String in YYYY-MM-DD format corresponding to the date that the meditation was recorded.
    Some meditations don't have this set yet, so null still has to be allowed.
  */
  dateRecorded: string | null;

  /**
    Meditation description. Is an empty string if blank.
  */
  description: string | "";

  /**
    Duration of the meditation file in seconds.
  */
  duration: number;

  /**
    which level of membership the user has.
    Is an object with two keys, corresponding to the ACF values for this field:

    Object { value: "tier_paid1", label: "Pro Membership" }

  */
  membershipLevel: object;

  /**
    Wordpress postID of the meditation. Needs to be phased out in favour of meditationId.
  */
  postId: number;

  /**
    postID of the Online Event that the meditation was recorded in, if applicable.

    Should probably be updated to allow the weekly meditation to be a possible source also.

  */
  sourceOnlineEvent: number | null;

  /**
    Object { spc_id: 15172, spc_name: "Coming into Balance Regarding Responsibility", spc_image_url_thumbnail: "/wp-content/uploads/2024/07/2024-07-balance-responsibility-150x150.webp", … }
  */
  sourceSpc: SourceSpc | null;

  /**
    Object of key value pairs of Wordpress taxonomy names and the assigned terms

    eg. Object { issue_targeted: '["metacognitive-development","self-agency","trauma"]', format: "[]", purpose: "[]", … }
  */
  taxonomyData: object;

  title: string;

  transcript?: string;
};

type Stats = {
  /**
    'timestamp' corresponds to the last time that one of the properties on this Meditation instance was updated. We use this timestamp  when merging local and server copies of the data to determine which one to keep.
  */
  timestamp: string | undefined;

  /**
    The 'lastPlayed' string is a Date string from Date.toString();
    For example: "Fri Nov 01 2024 16:02:41 GMT+0000 (Greenwich Mean Time)"
  */
  lastPlayed: string | undefined;

  /**
    How many times the meditation has been clicked 'play' on. Still need to determine how to calculate the time that needs to occur between clicks so that multiple start/stops don't increase the playcount beyond what we can infer to be a single 'meditation session'.
  */
  playcount: number;

  /**
    'downloaded' refers to whether the user has clicked 'download' on a meditation, which will store it in IndexedDB for offline mode.
  */
  downloaded: boolean;

  /**
    'completed' refers to whether the meditation has been listened all the way through at least one time.
  */
  completed: boolean;

  /**
    Whether the user has marked the meditation as a 'favourite' that they want quick access to.
  */
  favourite: boolean;
};

export class Meditation {
  /**
    Track all the instances and ensure there is only ever one of each
  */
  public static instances: Map<number, Meditation> = new Map();

  /**
    Instead of initialising this Class with `new` it should be initialised with this method. It returns a new instance if one doesn't exist.

    eg. const meditation = Meditation.getInstance(meditationSummaryData);
  */
  public static getInstance(
    meditationSummaryData: MeditationSummaryData,
  ): Meditation | undefined {
    const meditationId = meditationSummaryData.meditationId;

    if (Meditation.instances.has(meditationId)) {
      return Meditation.instances.get(meditationId)!;
    }

    const instance: Meditation = new Meditation(meditationSummaryData);
    Meditation.instances.set(Number(meditationId), instance);

    return instance;
  }

  /**
    Instance data
  */
  public userStats: Stats = {
    favourite: false,
    timestamp: undefined,
    completed: false,
    lastPlayed: undefined,
    playcount: 0,
    downloaded: false,
  };

  public readonly meditationId: number;
  public readonly postId: number;
  public readonly audioUrl: string | null;
  public readonly canAccess: boolean;
  public readonly dateRecorded: string | null;
  public readonly description: string | "";
  public readonly duration: number;
  public readonly membershipLevel: object;
  public readonly sourceOnlineEvent: number | null;
  public readonly taxonomyData: object;
  public readonly title: string;
  public readonly transcript?: string;
  public readonly sourceSpc?: SourceSpc | null;
  public SPC?: SPC | null;

  public player?: MeditationPlayer | null = null;

  private constructor(meditationSummaryData: MeditationSummaryData) {
    this.meditationId = meditationSummaryData.meditationId;
    this.audioUrl = meditationSummaryData.audioUrl;
    this.canAccess = meditationSummaryData.canAccess;
    this.dateRecorded = meditationSummaryData.dateRecorded;
    this.description = meditationSummaryData.description;
    this.duration = meditationSummaryData.duration;
    this.membershipLevel = meditationSummaryData.membershipLevel;
    this.postId = meditationSummaryData.postId;
    this.sourceOnlineEvent = meditationSummaryData.sourceOnlineEvent;
    this.sourceSpc = meditationSummaryData.sourceSpc;
    this.taxonomyData = meditationSummaryData.taxonomyData;
    this.title = meditationSummaryData.title;
    this.transcript = meditationSummaryData.transcript;

    this.initialiseSelf();

    /**
      This was working until I realised that sometimes there can be multiple SPC parents. I might add a way to turn that into an array to handle them but I need to handle that case later.
    */
    // if (this.sourceSpc) {
    //   this.SPC = SPC.getInstance({ spc_id: this.sourceSpc.spc_id });
    // }
  }

  private initialiseSelf() {
    this.userStats =
      window.Userdata.meditationData.usageData.get(this.meditationId)
        ?.userStats ?? this.userStats;
  }

  private instanceUpdated() {
    return (this.userStats.timestamp = new Date().toString());
  }

  private onChange?: () => void;

  public setChangeListener(callback: () => void): void {
    this.onChange = callback;
  }

  public emitChange() {
    if (this.onChange) this.onChange();
  }

  // download() { };
  // clearDownload() { };

  save() {
    window.Userdata.journalData.add(this);
  }

  delete() {
    window.Userdata.journalData.delete(this);
  }

  journalEntries() {
    return window.Userdata.journalData.getAllByKey(
      "meditationId",
      this.meditationId,
    );
  }

  update(
    key: "playcount" | "favourite" | "completed",
    value: string | number | boolean,
  ) {
    console.log("update!");
    // We create the object to pass to the key on the UserUsageHandler

    const meditationStatsObj = {
      [key]: value,
    };

    console.log("statsobj", meditationStatsObj);
  }

  toggleFavourite(newValue: boolean) {
    this.userStats.favourite = newValue || !this.userStats.favourite;
    this.saveToUserUsageData();
    this.watchFavourite();
    return this.userStats.favourite;
  }

  toggleCompleted(newValue: boolean) {
    console.log("spc toggle initial value:", this.userStats.completed);
    this.userStats.completed = newValue || !this.userStats.completed;
    console.log("spc toggle completed", this.userStats.completed);
    this.saveToUserUsageData();
    this.watchCompleted();
    this.notifySPC();
    return this.userStats.completed;
  }

  notifySPC() {

    /**
      There can be multiple sourceSPCs for a given meditation, because meditations are packaged in multiple different ones.
      Our single Meditation instance can't know which SPC it has been instantiated from, because the Meditation 'view' doesn't have it's own class yet.

      So for now, we notify the correct SPC by simply getting that data out of the DOM: get the slidePane that is currently open and it will have the ID there.

    */

    const spcPaneId = document
      .querySelector("div.pane_wrap.show[data-pane-type='spc']") as HTMLElement;

    if (spcPaneId) {
      const spcId = Number(spcPaneId.dataset.paneId);
      console.log("spc meditation-> notifyspc", spcId);
      if (!spcId) return;

      const spcInstance = SPC.instances.get(Number(spcId));
      console.log('spc meditation-> instance', spcInstance)
      if (spcInstance) {
        console.log('spc meditation-> YES! NOTIFY');
        spcInstance.signalFromChildMeditation();
      }
    }

  }

  watchCompleted() {
    /**
      Assign completed class to all rows
    */
    document
      .querySelectorAll(
        `div.meditation_row[data-meditation-id='${this.meditationId}']`,
      )
      .forEach((row) => {
        const rowElement = row as HTMLElement;
        if (rowElement) {
          const completed = this.userStats.completed;
          console.log("Update meditation row completed", completed);
          rowElement.dataset.completed = String(completed);

          /**
            Add or remove completed icon
          */
          rowElement
            .querySelector("iconify-icon.completed")
            ?.classList.toggle("on", completed);
        }
      });

    /**
      Assign to meditation Panes.
    */

    const paneHeaderIcon = document.querySelector(`
      div.pane_wrap[data-pane-id='${this.meditationId}']
      div.pane_title
      iconify-icon.header`);

    paneHeaderIcon?.setAttribute("icon", this.paneIcon());
    paneHeaderIcon?.classList.toggle("completed", this.userStats.completed);
  }

  paneIcon() {
    const checkIcon = this.userStats.completed
      ? icons.check.on
      : icons.lotusBold;
    return checkIcon;
  }

  watchFavourite() {
    // console.log('meditation instance updated: fav: ', this.userStats.favourite)

    /**
      Notifies sectionMeditation to run the updateFavsListDOM() function.

      ? Could probably make rows, panes, and menus listen to this.
    */

    this.emitChange();

    document.dispatchEvent(
      new CustomEvent("meditationFavUpdateEvent", {
        bubbles: true,
        composed: true,
      }),
    );

    /**
      Update all rows
    */
    document
      .querySelectorAll(
        `div.meditation_row[data-meditation-id='${this.meditationId}']`,
      )
      .forEach((row) => {
        const rowElement = row as HTMLElement;
        if (rowElement) {
          rowElement.dataset.favourite = String(this.userStats.favourite);
        }
      });

    /**
      Update all panes
    */
    const meditationPaneWrap = document.querySelector(
      `div.pane_wrap[data-pane-id='${this.meditationId}'] div.pane_container`,
    ) as HTMLElement;
    if (meditationPaneWrap) {
      meditationPaneWrap.dataset.favourite = String(this.userStats.favourite);
    }
  }

  updatePlaycount() {
    /**
      To decide if to update the count or not, we check if the meditation has been restarted since the last time this was pressed.
     **/

    this.userStats.lastPlayed = new Date().toString();
    this.userStats.playcount += 1;

    console.log("this.userStats.playcount", this.userStats.playcount);

    this.emitChange();
    this.saveToUserUsageData();

    document.dispatchEvent(
      new CustomEvent("meditation_update_playcount", {
        bubbles: true,
        composed: true,
        detail: {},
      }),
    );
  }

  saveToUserUsageData() {
    /**
      At some point I should define 'flatMeditation' to be just the relevant properties that meditations have, without the methods.
    **/

    /**
      Either we send an object with all the keys to update or just the ones we want, in both cases the UserUsageHandler will take care of it by adding the new values.
    **/

    const { ...flatMeditation }: Meditation = this;
    delete flatMeditation.player;

    this.instanceUpdated();
    window.Userdata.meditationData.update(flatMeditation);
  }
}
